<template>
  <div class="d-flex flex-column flex-grow-1 pa-4">
      <div class="text-h5 mb-1">
        Resource Links
      </div>
      <div v-if="showGaBranding" class="text-subtitle-1 text--secondary mb-2">
        School districts in the state of Georgia are advised to consult resources and templates from the Georgia Department of Education and the Georgia Emergency Management and Homeland Security Agency. 
      </div>

      <resource-link-cards></resource-link-cards>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ResourceLinkCards from '../../components/resources/ResourceLinkCards.vue';
export default {
  components: { ResourceLinkCards },
  computed: {
    ...mapState('app', [
      'showGaBranding',
    ]),
  },
};
</script>
