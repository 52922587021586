import Vue from 'vue'
import Router from 'vue-router'

import store from './../store';

// Routes
import PagesRoutes from './pages.routes'
import UsersRoutes from './users.routes'
import LandingRoutes from './landing.routes'
import { appConfig } from '../app-config';

import DashboardPage from '@/pages/dashboard/DashboardPage.vue'

Vue.use(Router)

export const routes = [
  {
    path: '/firebase/action',
    meta: {
      authNotRequired: true,
    },
    beforeEnter(to, from, next) {
      if (to.query.mode === 'resetPassword') {
        return next({ name: 'auth-set-password', query: {
          oobCode: to.query.oobCode,
          email: to.query.continueUrl.split('?email=')[1],
        } });
      }
      if (to.query.mode === 'revertSecondFactorAddition') {
        return next({ name: 'auth-revert-mfa', query: {
          oobCode: to.query.oobCode,
        } });
      }
      if (to.query.mode === 'verifyEmail') {
        return next({ name: 'auth-verify-email', query: {
          oobCode: to.query.oobCode,
        } });
      }
      if (to.query.mode === 'signIn') {
        return next({ name: 'auth-handle-sign-in-link', query: {
          oobCode: to.query.oobCode,
          apiKey: to.query.apiKey,
          mode: to.query.mode,
          lang: 'en',
        } });
      }
      return next('/');
    },
  },
  {
    path: '/sign-out',
    meta: {
      authNotRequired: true,
    },
    beforeEnter(to, from, next) {
      store.dispatch('app/logOut');
      return next({ name: 'auth-signin' });
    },
  },
  {
    path: '/',
    redirect: '/dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardPage,
  },
  ...PagesRoutes,
  ...UsersRoutes,
  ...LandingRoutes,
  {
    path: '*',
    name: 'error',
    component: () => import(/* webpackChunkName: "error" */ '@/pages/error/NotFoundPage.vue'),
    meta: {
      layout: 'error',
    },
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes,
})

/**
 * Before each route update
 */
router.beforeEach(async (to, from, next) => {
  if (to.meta.authNotRequired) {
    return next();
  } else {
    const auth = store.state.app.auth;
    const firebaseUser = auth.currentUser;
    const isAuthInitialized = store.state.app.isAuthInitialized;

    if (appConfig.enableAutologout) {
      const rawInactivityTimestamp = localStorage.getItem('lastActivity');
      const inactivityTimestamp = rawInactivityTimestamp ? parseInt(rawInactivityTimestamp, 10) : false;
      if (inactivityTimestamp && Date.now() - inactivityTimestamp > 24 * 60 * 60 * 1000) {
        localStorage.removeItem('lastActivity');
        store.dispatch('app/logOut');
        store.commit('app/showToast', {
          message: 'You were automatically signed out due to inactivity',
        })
        return next({ name: 'auth-signin' });
      }
    }

    if (!isAuthInitialized) {
      try {
        const user = await waitForAuthInit(auth);
        if (user) {
          return next();
        } else {
          return next({ name: 'auth-signin' });
        }
      } catch (e) {
        store.dispatch('app/logOut');
        return next({ name: 'auth-signin', query: { issue: 'authInitTimeout' } });
      }
    } else if (firebaseUser) {
      return next();
    } else {
      return next({ name: 'auth-signin' })
    }
  }
})

async function waitForAuthInit(auth, timeout = null) {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      unsubscribe();
      resolve(firebaseUser);
    });
    if (timeout) {
      setTimeout(() => {
        unsubscribe();
        reject(new Error('Auth init timeout'));
      }, timeout);
    }
  });
}

export default router
