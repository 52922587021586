import Vue from 'vue'
import moment from 'moment-timezone'
import store from '../store'
import { Timestamp } from 'firebase/firestore'

Vue.filter('formatDate', (value, filterFormat) => {
  const { zone, format } = store.state.app.time

  const isTimestamp = value instanceof Timestamp
  const isMalformedTimestamp = !isTimestamp && value && value._seconds && value._nanoseconds

  if (value) {
    if (isTimestamp) {
      return moment(value.toDate()).tz(zone).format(filterFormat || format || 'lll')
    }
    if (isMalformedTimestamp) {
      console.warn('Malformed timestamp:', value)
      return moment(value._seconds * 1000 + value._nanoseconds / 1000000).tz(zone).format(filterFormat || format || 'lll')
    }
    return moment(value).tz(zone).format(filterFormat || format || 'lll')
  }

  return ''
})
