<template>
  <div class="d-flex flex-column flex-grow-1 pa-4">
    <h1>Terms and Conditions</h1>

    <h2>Agreement between User and SCHOOLDOG.</h2>
    <p>The SCHOOLDOG web application (the "Site") is a web-based application comprised of various web-based pages operated by SchoolDog Solutions, Inc. (the “Provider”). The Site is authorized for use only by a school district or entity (the “Recipient”) and its employees, contractors, sub-contractors, and 3rd Party agents such as law enforcement personnel (collectively “Agents”) designated and authorized by the Recipient.</p>
    <p>Use of the Site is conditioned on the Recipient’s acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). The Recipient’s and its Agents’ use of the Site constitutes their agreement to all such Terms. Please read these terms carefully and keep a copy of them for your reference.</p>
    <p>The Recipient and Agents and any other authorized user also acknowledge and agree to the following terms and conditions:</p>
    <ol type="A">
      <li>That SCHOOLDOG is a web-based application for data-entry, data reporting, and notifications for discretionary use by the RECIPIENT and its designated and authorized Agents for the purpose of recording and storing perceived qualitative observations by its Agents regarding the Recipient’s implementation of school-district best practices identified by the Recipient.</li>
      <li>The content and manner of entry of any observational data entries that are entered into SCHOOLDOG by the Recipient and its Agents are facilitated and utilized in the Recipient’s sole discretion. The decision on whether and in what manner to interpret or respond to data, communications, and notifications related to the use of SCHOOLDOG by the Recipient and its Agents rests solely with the Recipient alone.</li>
      <li>The Recipient agrees and acknowledges that the Recipient has not awarded or delegated to the Provider any implied or explicit decision-making, policy-making, administrative, supervisory, or legal responsibility or accountability or liability for the development and implementation and supervision of the Recipient’s District and School Safety Plans and the Recipient’s administrative actions regarding the safety and health of the Recipient’s stakeholders as defined by state and federal law. In addition, the Recipient agrees that the Recipient has not awarded or delegated to the Provider any implied or explicit decision-making, policy-making, administrative, supervisory, or legal responsibility or liability for the development and implementation and supervision of any practice or policy regarding school and workplace safety, climate and culture, stakeholder health, prevention of or response to acts of violence or other criminal acts, prevention of or response to emergencies or times of crisis, and facility and energy management practices.</li>
      <li>That all administrative decisions, practices, actions, non-actions, or policy implementations administered by the Recipient and its Agents related to the Recipient’s use of the services provided by the Provider are made in the sole discretion and professional expertise of the Recipient and its Agents in accordance with state/federal law and the Recipient’s own policies and procedures. The Recipient agrees to indemnify, defend, and hold harmless the Provider for any decision, policy, practice, action, non-action, omission, act of negligence, or failure to act made by the Recipient and its Agents related to its use of the Provider’s software and services or based on the verbal, electronic, or written interactions, notifications, data entries, conversations, or consultations received from the Provider or communicated between employees, officers, contractors, subcontractors, or third-party agents of the Provider and the Recipient.</li>
    </ol>

    <h2>Electronic Communications</h2>
    <p>Using the Site or sending emails to THE PROVIDER constitutes electronic communications. You consent to receive electronic communications, and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site satisfy any legal requirement that such communications be in writing.</p>
    
    <h2>Your Account’s Security</h2>
    <p>All Agents and users are responsible for maintaining the confidentiality of their account and password and for restricting access to your computer, and Agents and users agree to accept responsibility for all activities that occur under their account or password. Agents and users may not assign or otherwise transfer their account to any other person or entity. Agents and users acknowledge that The Provider is not responsible for third party access to their account that results from theft or misappropriation of their account. The Provider and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>

    <h2>Children Under Thirteen</h2>
    <p>The Provider does not knowingly collect, either online or offline, personal information from persons under the age of thirteen.</p>

    <h2>Cancellation/Refund Policy</h2>
    <p>School districts may cancel their subscription of The Provider via the terms outlined in their service agreement.</p>

    <h2>Links to Third Party Sites/Third Party Services</h2>
    <p>The Recipient acknowledges that the Site may contain links to other websites, as well as other resources, and the Site may contain materials uploaded by the Recipient itself (collectively "Linked Sites"). The Recipient agrees that the content of Linked Sites are not under the control or supervision of the Provider, and the Recipient agrees that the Provider is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. The Provider is providing access to these links only as a convenience and as an additional resource for the Recipient to consider according to its own discretion, and the inclusion of any Linked Sites does not imply endorsement by the Provider of the site or any association with its operators.</p>

    <h2> No Unlawful or Prohibited Use/Intellectual Property</h2>
    <p>The Recipient and its Agents are granted a non-exclusive, non-transferable, revocable license to access and use the Site strictly in accordance with these terms of use. As a condition of the Recipient’s use of the Site, The Recipient and its Agents warrant to SCHOODOG that they will not use the Site for any purpose that is unlawful or prohibited by these Terms. Users may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. Users may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.</p>
    <p>All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of THE PROVIDER or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. Users agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.</p>
    <p>Users will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. THE PROVIDER’s content is not for resale. Users’ utilization of the Site does not entitle users to make any unauthorized use of any protected content, and in particular users will not delete or alter any proprietary rights or attribution notices in any content. User will use protected content solely for their professional use under the terms of service and will make no other use of the content without the express written permission of  and the copyright owner. Users agree that they do not acquire any ownership rights in any protected content. We do not grant users any licenses, express or implied, to the intellectual property of THE PROVIDER or our licensors except as expressly authorized by these Terms.</p>

    <h2>International Users</h2>
    <p>The Service is controlled, operated and administered by THE PROVIDER from our offices within the USA. If users access the Service from a location outside the USA, you are responsible for compliance with all local laws. Users agree that they will not use the The Provider’s Content accessed through the Site in any country or in any manner prohibited by any applicable laws, restrictions or regulations.</p>

    <h2>Indemnification</h2>
    <p>The Recipient and its Agents agree to indemnify, defend and hold harmless SCHOOLDOG Solutions, Inc., its officers, directors, employees, agents and third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees) relating to or arising out of the Recipient’s use of or inability to use the Site or services, any postings or data entries made or not made by the Recipient and its Agents, user violation of any terms of this Agreement or user violation of any rights of a third party, or user violation of any applicable laws, rules or regulations. The Provider reserves the right, at its own cost, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with The Provider in asserting any available defenses.</p>

    <h2>Class Action Waiver</h2>
    <p>Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted. THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and The Provider agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.</p>

    <h2>Liability Disclaimer</h2>
    <p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. SCHOOLDOG SOLUTIONS, INC. AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.</p>
    <p>SCHOOLDOG SOLUTIONS, INC. AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND. SCHOOLDOG SOLUTIONS, INC. AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.</p>
    <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL SCHOOLDOG SOLUTIONS, INC. AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF SCHOOLDOG SOLUTIONS, INC. OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.</p>

    <h2>Termination/Access Restriction</h2>
    <p>The Provider reserves the right, in its sole discretion, to terminate the Recipient’s access to the Site and the related services or any portion thereof at any time, without notice for any one of the following three reasons: (1) a reason not inconsistent with the executed service agreement between THE PROVIDER and the Recipient; (2) for the purposes of complying with state or federal law; (3) to conduct necessary Site maintenance and updates for Site functionality, confidentiality, and security.</p>

    <h2>Jurisdiction</h2>
    <p>To the maximum extent permitted by law, this agreement is governed by the laws of the State of Georgia unless otherwise agreed mutually in a fully executed service agreement between THE PROVIDER and a specific Recipient, and the user hereby consents, unless an individual Recipient and THE PROVIDER have formally agreed otherwise in writing, to the exclusive jurisdiction and venue of courts in Georgia in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including, without limitation, this section.</p>
    <p>The Provider's performance of this agreement is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of The Provider's right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by The Provider with respect to such use. If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall continue in effect.</p>
    <p>Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and The Provider with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or written, between the user and The Provider with respect to the Site. A printed version of this agreement and of any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.</p>

    <h2>Changes to Terms</h2>
    <p>The Provider reserves the right, in its sole discretion, to change the Terms under which the Site is offered. The most current version of the Terms will supersede all previous versions. The Provider encourages you to periodically review the Terms to stay informed of our updates.</p>

    <h2>Contact Us</h2>
    <p>SchoolDog welcomes your questions or comments regarding the Terms.</p>
    
    <p>470-876-7070
    <p><a href="mailto:support@schooldogsolutions.com">support@schooldogsolutions.com</a></p>
    
    <p>Effective as of May 27, 2024</p>
  </div>
</template>

<script>
export default {
};
</script>
