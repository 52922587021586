import { signOut } from 'firebase/auth';

import AppSettings from './../../models/AppSettings';
import Cycle from './../../models/Cycle';
import Settings from './../../models/Settings';
import Task from './../../models/Task';
import User from './../../models/User';
import Walk from './../../models/Walk';
import WalkSettings from './../../models/WalkSettings';
import SchoolInfo from '../../models/SchoolInfo';
import Rubric from '../../models/Rubric';
import RubricRating from '../../models/RubricRating';
import SchoolLocations from '../../models/SchoolLocations';
import Observation from '../../models/Observation';
import ObservationDelta from '../../models/ObservationDelta';
import { initializeFirebaseServices, resetFirebase, messaging } from '../../firebase-setup';
import FunctionsService from './../../services/FunctionsService';

import router from '../../router';
import { disableNetwork, enableNetwork, Timestamp } from 'firebase/firestore';
import { getToken } from 'firebase/messaging';

const showToast = ({ state, commit }, message) => {
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: 'black',
      message,
      timeout: 3000,
    })
  })
}

const showError = ({ state, commit }, { message = 'Failed!', error, buttonText, action, displayAtTop }) => {
  if (state.toast.show) commit('hideToast')

  displayAtTop = displayAtTop || false;

  setTimeout(() => {
    commit('showToast', {
      color: 'error',
      message: `${message}${error?.message ? ` ${error.message}` : ''}`,
      buttonText,
      action,
      timeout: 10000,
      dismissable: true,
      displayAtTop,
    })
  })
}

const showSuccess = ({ state, commit }, msg) => {
  // if action is a string, it is a message
  const message = typeof msg === 'string' ? msg : msg.message
  const buttonText = typeof msg === 'string' ? null : msg.buttonText
  const action = typeof msg === 'string' ? null : msg.action
  if (state.toast.show) commit('hideToast')

  setTimeout(() => {
    commit('showToast', {
      color: 'primary lighten-1',
      message,
      timeout: buttonText ? 4500 : 3000,
      buttonText,
      action,
      dismissable: true,
    })
  })
}

const setUserFromFirebaseUser = async ({ commit, dispatch, state }) => {
  if (state.auth.currentUser && state.auth.currentUser.uid) {
    const inactivityTimestamp = Date.now();
    localStorage.setItem('lastActivity', inactivityTimestamp.toString());

    const user = await User.getById(state.auth.currentUser.uid);

    user.startListening(async (user) => {
      if (user.autoSignOutData) {
        const autoSignOutKey = user.autoSignOutData.key;
        const timestamp = user.autoSignOutData.timestamp;
        const key = localStorage.getItem(`user-${user.id}-asok-${autoSignOutKey}`);
        const signInTimestamp = parseInt(localStorage.getItem('signInTimestamp') || 0);

        if (!key && signInTimestamp < timestamp) {
          localStorage.setItem(`user-${user.id}-asok-${autoSignOutKey}`, '1');
          dispatch('logOut');
        }
      }

      if (!state.isOffline) {
        await state.auth.currentUser.getIdToken(true)
      }
    })
    if (user) {
      commit('setUser', user);
      dispatch('syncWalks');
      dispatch('syncTasks');
      dispatch('syncRubrics');
      dispatch('syncRubricRatings');
      dispatch('syncSchoolInfos')
      dispatch('syncSchoolLocations')
      dispatch('initialize');
      dispatch('initializeMessagingToken');
    } else {
      commit('setUser', null);
    }
  } else {
    commit('setUser', null);
  }
  if (!state.isAuthInitialized) {
    commit('setIsAuthInitialized', true);
  }
};

async function clearMessagingToken({ commit, state }) {
  if (state.messagingToken && state.user && state.user.messagingTokens && state.user.messagingTokens[state.messagingToken]) {
    const user = state.user;
    delete user.messagingTokens[state.messagingToken];
    await FunctionsService.run('editUser', {
      userId: user.id,
      updatedUserData: user.toJson(),
    });
  }

  commit('setMessagingToken', null);
}

async function initializeMessagingToken({ commit, state }) {
  if (state.messagingToken || state.numTimesRequestedPermission >= 2 || state.isOffline) {
    return;
  }

  try {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      const currentToken = await getToken(messaging);

      const user = state.user;
      if (!user.messagingTokens[currentToken]) {
        user.messagingTokens[currentToken] = true
        await FunctionsService.run('editUser', {
          userId: user.id,
          updatedUserData: user.toJson(),
        });
      }

      commit('setMessagingToken', currentToken);
    }
  } catch (e) {
    console.error('Error getting current token', e);
  } finally {
    commit('setNumTimesRequestedPermission', state.numTimesRequestedPermission + 1);
  }
}

async function logOut({ commit, dispatch, state }) {
  try {
    if (state.isManuallyOffline) {
      commit('setIsManuallyOffline', false);
      enableNetwork(state.db);
    }
    dispatch('clearMessagingToken');
    await signOut(state.auth);

    await resetFirebase(true);
    initializeFirebaseServices(true);
  } catch (e) {
    console.warn('Error signing out', e);
  } finally {
    // However, we must ensure sensitive data is wiped if attempting to log out.
    dispatch('resetState');
  }
}

function resetState({ commit, state }) {
  if (state.globalAppSettings && state.globalAppSettings.stopListening) {
    state.globalAppSettings.stopListening();
  }
  commit('setGlobalAppSettings', new AppSettings());
  commit('setUser', null);
  commit('setUsers', []);
  commit('setOrganizationSettings', new Settings());
  commit('setObservationSettings', new Settings());
  commit('setWalkSettings', new Settings());
  commit('setWalks', []);
  commit('setTasks', []);
  commit('setCycles', []);
  commit('setSchoolInfos', []);
  commit('setIsAuthInitialized', false);
  commit('setHaveWalksLoaded', false);
  commit('setHaveTasksLoaded', false);
  commit('setLastTimeWalksUpdated', null);
  commit('setLastTimeTasksUpdated', null);

  localStorage.removeItem('lastActivity');
  localStorage.removeItem('signInTimestamp');
}

async function loadAppSettings({ commit, dispatch }) {
  const appSettings = await AppSettings.getById('global');
  appSettings.startListening(() => {
    commit('setGlobalAppSettings', appSettings);
  });
  commit('setGlobalAppSettings', appSettings);
}

async function initialize({ commit, state, dispatch }) {
  // Initiating all asynchronous operations concurrently
  const promiseMap = {
    organizationSettings: Settings.getById('organization', true),
    users: User.getAll(),
    appSettings: dispatch('loadAppSettings'),
  };
  const user = state.user;
  if (user.can('manage cycles')) {
    promiseMap.walkSettings = WalkSettings.getById('walk', true);
    promiseMap.cycles = Cycle.getAll();
  }
  if (user.canAtAnySchool('log entries')
    || user.canAtAnySchool('manage entries')
    || user.canAtAnySchool('view data insights')
    || user.canAtAnySchool('be proposed tasks')
    || user.canAtAnySchool('propose tasks')
    || user.can('manage other entries'))  {
    promiseMap.observationSettings = Settings.getById('observation', true);
  }

  // Await all promises and store the resolved values
  const resolvedValues = await Promise.all(Object.values(promiseMap));

  // Create an array of the keys to map resolved values correctly
  const keys = Object.keys(promiseMap);

  // Create an object to map keys to their resolved values
  const results = {};
  keys.forEach((key, index) => {
    results[key] = resolvedValues[index];
  });

  // Once all promises are resolved, commit the results
  commit('setUsers', results.users || []);
  commit('setOrganizationSettings', results.organizationSettings || {});
  commit('setObservationSettings', results.observationSettings || {});
  commit('setWalkSettings', results.walkSettings || {});
  commit('setCycles', results.cycles || []);
  commit('setHaveCyclesLoaded', true);
}

async function syncWalks({ commit, state, dispatch }) {
  // Need to wait to load walks until we know which user to load from
  if (!state.user) {
    return
  }

  const user = state.user

  const timeLastUpdated = state.lastTimeWalksUpdated
  commit('setLastTimeWalksUpdated', new Date())

  commit('setHaveWalksLoaded', false)

  const walkQueryPromises = [];

  if (!state.user.isSchoolDogStaff) {
    const hasFullManageAccess = user.can('manage entries');
    let partialFullAccessSchoolIds = [];
    if (!hasFullManageAccess && user.canAtAnySchool('manage entries')) {
      partialFullAccessSchoolIds = [
        ...new Set([
          ...(user.permissions['manage entries']?.forSchools || []),
        ]),
      ];
      partialFullAccessSchoolIds.forEach((schoolId) => {
        walkQueryPromises.push(Walk.getAllWhere([
          [
            'schoolId',
            '==',
            schoolId,
          ],
          [
            'walkType',
            '==',
            'school',
          ],
        ]))
      })
    } else if (hasFullManageAccess) {
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'walkType',
          '==',
          'school',
        ],
      ]))
    }

    if (user.can('manage other entries')) {
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'walkType',
          '==',
          'partner',
        ],
      ]))
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'walkType',
          '==',
          'district',
        ],
      ]))
    }

    const hasLogAccess = user.can('log entries');
    let partialAccessSchoolIds = [];
    if (!hasLogAccess && user.canAtAnySchool('log entries')) {
      partialAccessSchoolIds = [
        ...new Set([
          ...(user.permissions['log entries']?.forSchools || []),
        ]),
      ];
      partialAccessSchoolIds.forEach((schoolId) => {
        walkQueryPromises.push(Walk.getAllWhere([
          [
            'collaborators',
            'array-contains',
            state.user.id,
          ],
          [
            'schoolId',
            '==',
            schoolId,
          ],
        ]))
        walkQueryPromises.push(Walk.getAllWhere([
          [
            'leaderUserId',
            '==',
            state.user.id,
          ],
          [
            'schoolId',
            '==',
            schoolId,
          ],
        ]))
      })
    } else if (hasLogAccess) {
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'collaborators',
          'array-contains',
          state.user.id,
        ],
      ]))
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'leaderUserId',
          '==',
          state.user.id,
        ],
      ]))
    }

    const hasViewCompletedAccess = user.can('view entries');
    let partialViewCompletedSchoolIds = [];
    if (!hasViewCompletedAccess && user.canAtAnySchool('view entries')) {
      partialViewCompletedSchoolIds = [
        ...new Set([
          ...(user.permissions['view entries']?.forSchools || []),
        ]),
      ];
      partialViewCompletedSchoolIds.forEach((schoolId) => {
        walkQueryPromises.push(Walk.getAllWhere([
          [
            'schoolId',
            '==',
            schoolId,
          ],
          [
            'status',
            '==',
            'complete',
          ],
        ]))
      })
    } else if (hasViewCompletedAccess) {
      walkQueryPromises.push(Walk.getAllWhere([
        [
          'status',
          '==',
          'complete',
        ],
      ]))
    }
  } else {
    walkQueryPromises.push(Walk.getAll())
  }

  const results = await Promise.all(walkQueryPromises)
  const allWalks = results.flat();
  await dispatch('syncRubricRatings');

  if (allWalks.length) {
    // The mutation will filter duplicates keeping the ones at the end of the array
    const walks = [
      ...state.walks,
      ...allWalks,
    ]
    commit('setWalks', walks);
  }

  commit('setHaveWalksLoaded', true)
}

async function syncRubrics({ commit, state }) {
  if (!state.user || !(state.user.canAtAnySchool('log entries') || state.user.canAtAnySchool('submit ratings') || state.user.canAtAnySchool('view ratings') || state.user.can('manage rubrics') || state.user.can('manage other entries') || state.user.canAtAnySchool('manage entries'))) {
    return
  }
  const conditions = [
    [
      'isDeleted',
      '!=',
      true,
    ],
  ]
  const rubrics = await Rubric.getAllWhere(conditions);
  commit('setRubrics', rubrics);
}

async function createRubricRating({ commit, getters }, { rubricId, schoolId, createdByUserId, additionalParams }) {
  const data = getters.getRubricById(rubricId).getDataToSave();
  data.allEntries = [];
  data.rubricId = rubricId;
  data.schoolId = schoolId;
  data.createdByUserId = createdByUserId;
  data.status = 'created';
  data.sections.forEach((section) => {
    section.questions.forEach((question) => {
      question.linkedObservations = [];
      question.score = null;
      question.manuallyAnswered = false;
      question.autoLinkedScore = false;
    })
  });
  const rubricRating = new RubricRating(null, {...data, ...additionalParams});
  return rubricRating.save().then((submission) => {
    commit('addOrUpdateRubricRating', rubricRating);
    return submission;
  });
}

async function saveRubricRating ({ commit }, rubricRating) {
  return rubricRating.save().then((submission) => {
    commit('addOrUpdateRubricRating', rubricRating);
    return submission;
  })
}

async function submitRubricRating ({ dispatch }, { rubricRating, userId }) {
  rubricRating.status = 'submitted';
  rubricRating.submittedByUserId = userId;
  rubricRating.timeSubmitted = Timestamp.now();
  return await dispatch('saveRubricRating', rubricRating);
}

async function editRubricRating({ dispatch}, {rubricRating, userId}) {
  rubricRating.status = 'editing';
  rubricRating.editedByUserId = userId;
  rubricRating.submittedByUserId = null;
  rubricRating.timeLastSubmitted = rubricRating.timeSubmitted;
  rubricRating.timeSubmitted = null;
  return await dispatch('saveRubricRating', rubricRating);
}

async function deleteRubricRating({ dispatch }, {rubricRating, userId}) {
  rubricRating.status = 'deleted';
  rubricRating.deletedByUserId = userId;
  rubricRating.timeDeleted = Timestamp.now();
  rubricRating.isDeleted = true;
  return await dispatch('saveRubricRating', rubricRating);
}

async function syncRubricRatings({ commit, state }) {
  if (!state.user || !(state.user.canAtAnySchool('view ratings') || state.user.canAtAnySchool('submit ratings'))) {
    return
  }

  const queries = []
  if (state.user.canAtAnySchool('view ratings')) {
    const readConditions = [
      [
        'isDeleted',
        '!=',
        true,
      ],
      [
        'status',
        '==',
        'submitted',
      ],
    ]
    // if user 'can' view ratings, we don't have to filter by school (perm is just true)
    // if they 'cant', but we're here (so they 'canAtAnySchool') we know they have some schools but not all and need to filter down
    if (!state.user.can('view ratings')) {
      const validSchoolIdsRead = [
        ...new Set([
          ...(state.user.permissions['view ratings']?.forSchools || []),
        ]),
      ];
      readConditions.push([
        'schoolId',
        'in',
        validSchoolIdsRead,
      ]);
    }
    queries.push(RubricRating.getAllWhere(readConditions));
  }
  if (state.user.canAtAnySchool('submit ratings')) {
    const writeConditions = [
      [
        'isDeleted',
        '!=',
        true,
      ],
      [
        'createdByUserId',
        '==',
        state.user.id,
      ],
    ]
    // if user 'can' view ratings, we don't have to filter by school (perm is just true)
    // if they 'cant', but we're here (so they 'canAtAnySchool') we know they have some schools but not all and need to filter down
    if (!state.user.can('submit ratings')) {
      const validSchoolIdsWrite = [
        ...new Set([
          ...(state.user.permissions['submit ratings']?.forSchools || []),
        ]),
      ];
      writeConditions.push([
        'schoolId',
        'in',
        validSchoolIdsWrite,
      ]);
    }
    queries.push(RubricRating.getAllWhere(writeConditions));
  }
  const results = await Promise.all(queries);
  const rubricRatings = results.flat();

  commit('setRubricRatings', rubricRatings);
}

async function updateStoredRubric({ state }, rubric) {
  let storedRubric = state.rubrics.find((r) => r.id === rubric.id) || null;
  let isNew = false;
  if (!storedRubric) {
    storedRubric = new Rubric(null, rubric);
    storedRubric.timeCreated = Timestamp.now();
    storedRubric.createdByUserId = state.user?.id;
    isNew = true;
  } else {
    Object.keys(rubric).forEach((key) => {
      // don't want to override these keys
      if (key == 'timeCreated' || key == 'timeUpdated' || key == 'id') {
        return;
      }
      storedRubric[key] = rubric[key];
    });
  }
  storedRubric.timeUpdated = Timestamp.now()
  storedRubric.updatedByUserId = state.user?.id;
  await storedRubric.save();
  if (isNew) {
    state.rubrics.push(storedRubric);
  }
  return storedRubric;
}

async function deleteRubric({ state }, rubric) {
  try {
    const storedRubric = state.rubrics.find((r) => r.id == rubric.id) || null;
    if (storedRubric) {
      storedRubric.timeUpdated = Timestamp.now();
      storedRubric.timeDeleted = Timestamp.now();
      storedRubric.deletedByUserId = state.user?.id;
      storedRubric.isDeleted = true;
      await storedRubric.save();
      state.rubrics = state.rubrics.filter((r) => r.id !== rubric.id);
    }
    return true;
  } catch (error) {
    console.error('Error deleting rubric', error);
    return false;
  }
}

async function syncTasks({ commit, state }) {
  // Need to wait to load tasks until we know which user to load from
  if (!state.user) {
    return
  }

  const timeLastUpdated = state.timeLastUpdated
  commit('setLastTimeTasksUpdated', new Date())

  commit('setHaveTasksLoaded', false)

  let tasks = [
    ...state.tasks,
  ]

  for (let i = 0; i < 3; i++) {
    const field = [
      'assignedToUserId',
      'createdByUserId',
      'delegatedByUserId',
    ][i]

    const conditions = [
      [
        field,
        '==',
        state.user.id,
      ],
    ]

    // if (timeLastUpdated) {
    //   conditions.push([
    //     'timeUpdated',
    //     '>=',
    //     timeLastUpdated,
    //   ])
    // }
    const recentlyUpdatedTasks = await Task.getAllWhere(conditions)

    if (recentlyUpdatedTasks.length) {
      // The mutation will filter duplicates keeping the ones at the end of the array
      tasks = [
        ...tasks,
        ...recentlyUpdatedTasks,
      ]
    }
  }

  commit('setTasks', tasks);
  commit('setHaveTasksLoaded', true)
}

async function syncSchoolInfos({ commit, state }) {
  const conditions = []
  commit('setSchoolInfos', await SchoolInfo.getAllWhere(conditions));
}

async function syncSchoolLocations({ commit, state }) {
  const user = state.user;
  if (user.can('log entries')) {
    commit('setSchoolLocations', await SchoolLocations.getAll());
  } else if (user.canAtAnySchool('log entries')) {
    const validSchoolIds = [
      ...new Set([
        ...(user.permissions['log entries']?.forSchools || []),
      ]),
    ];
    const batchSize = 10;
    const promises = [];
    for (let i = 0; i < validSchoolIds.length; i += batchSize) {
      const batch = validSchoolIds.slice(i, i + batchSize);
      promises.push(SchoolLocations.getAllWhere([
        [
          '__documentId__',
          'in',
          batch,
        ],
      ]))
    }
    const results = await Promise.all(promises);
    const schoolLocations = results.flat();
    commit('setSchoolLocations', schoolLocations);
  } else {
    commit('setSchoolLocations', []);
  }

}

function createUserFromObject(_, { id, data }) {
  return new User(id, data);
}

function createModel(_, { model, id, data }) {
  return new {
    Task,
    User,
    Walk,
    Rubric,
    SchoolInfo,
    SchoolLocations,
    Observation,
    ObservationDelta,
  }[model](id, data);
}

function goToSignInIfNeeded() {
  if (router.currentRoute.meta.authNotRequired) {
    return;
  }
  router.push({ name: 'auth-signin' });
}

async function setManualOfflineMode({ commit, state }, isManuallyOffline) {
  if (isManuallyOffline) {
    await disableNetwork(state.db);
  } else {
    await enableNetwork(state.db);
  }
  commit('setIsManuallyOffline', isManuallyOffline);
}

export default {
  createUserFromObject,
  createRubricRating,
  initialize,
  loadAppSettings,
  logOut,
  resetState,
  editRubricRating,
  deleteRubric,
  deleteRubricRating,
  saveRubricRating,
  submitRubricRating,
  showToast,
  showError,
  showSuccess,
  setUserFromFirebaseUser,
  syncSchoolInfos,
  syncSchoolLocations,
  syncRubrics,
  syncRubricRatings,
  syncTasks,
  syncWalks,
  updateStoredRubric,
  createModel,
  goToSignInIfNeeded,
  setManualOfflineMode,
  clearMessagingToken,
  initializeMessagingToken,
}
