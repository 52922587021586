import FirestoreModel from './FirestoreModel';

class Observation extends FirestoreModel {
    static collectionName = 'observations';
    static allowedFields = [
      'comment',
      'imagePaths',
      'location',
      'observationCategoryId',
      'observationItemId',
      'observationType',
      'reportedByUserId',
      'schoolId',
      'timestamp',
      'walkId',
      'walkType',
      'quantity',
      'tags',
      'searchableTags',
      'searchableLocation',
      'isFloating',
      'shouldAssignAsTask',
      'taskAssignUserId',
      'taskDescription',
      'taskTitle',
      'rubricRatingId',
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default Observation;
