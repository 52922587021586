import { appConfig } from '@/app-config';
import { deleteApp, getApps, initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { clearIndexedDbPersistence, getFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Add this import
import { getFunctions } from 'firebase/functions';
import { getMessaging } from 'firebase/messaging';
import 'firebase/storage';

import store from '@/store';

import Vue from 'vue';

let app, analytics, db, auth, functions, storage, messaging;

export async function resetFirebase (shouldClearPersistence = false) {
  const apps = getApps();
  if (apps.length) {
    await Promise.all(apps.map(app => deleteApp(app)));
  }
  initializeFirebaseServices(shouldClearPersistence);
}

export function initializeFirebaseServices (shouldClearPersistence = false) {
  const app = initializeApp({
    apiKey: appConfig.apiKey,
    authDomain: appConfig.authDomain,
    projectId: appConfig.projectId,
    storageBucket: appConfig.storageBucket,
    messagingSenderId: appConfig.messagingSenderId,
    appId: appConfig.appId,
    measurementId: appConfig.measurementId,
  });

  initializeFirestore(app, 
    {
      localCache: 
      persistentLocalCache({
        tabManager: persistentMultipleTabManager(),
      }),
    },
  );

  analytics = getAnalytics(app);
  db = getFirestore(app);
  auth = getAuth(app);
  functions = getFunctions(app);
  storage = getStorage(app);
  messaging = {};
  try {
    messaging = getMessaging(app);
  } catch (e) {
    console.log('Failed to initialize messaging', e);
  }

  if (shouldClearPersistence) {
    clearIndexedDbPersistence(db);
  }

  Vue.prototype.db = db;
  Vue.prototype.auth = auth;
  Vue.prototype.storage = storage;

  store.commit('app/setDb', db);
  store.commit('app/setAuth', auth);

  onAuthStateChanged(store.state.app.auth, async (firebaseUser) => {
    store.commit('app/setIsAuthInitialized', true);
    console.log('auth update', Boolean(firebaseUser))
    await store.dispatch('app/setUserFromFirebaseUser');
    if (!firebaseUser) {
      store.dispatch('app/resetState');
      store.dispatch('app/goToSignInIfNeeded');
    }
  });
}

export { app, analytics, db, auth, functions, storage, messaging };