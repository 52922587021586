<template>
  <div class="pa-3 min-w-0 w-full">
    <div class="text-h5">
      Get Support
    </div>
    <div>
      If you need technical support or partner assistance, please contact us at <a href='mailto:support@schooldogsolutions.com'>support@schooldogsolutions.com</a> or <a href='tel:+14708767070'>470-876-7070</a>, and one of our staff will assist you.
    </div>


    <!-- faqs -->
    <v-row>
      <!-- <v-col cols="12" md="3" xl="2">
        <div class="sticky" style="top: 90px">
          <div class="title mb-2">Contents</div>
          <div v-for="faq in faqs" :key="faq.id" class="mb-1">
            <v-btn text color="primary" @click="$vuetify.goTo(`#${faq.id}`, { offset: 30 })">{{ faq.title }}</v-btn>
          </div>
        </div>
      </v-col> -->
      

      <!-- <v-col cols="12" md="9" xl="10"> -->
      <v-col cols="12">
        <div v-for="faq in faqs" :id="faq.id" :key="faq.id">
          <div id="#general" class="title pb-2">{{ faq.title }}</div>
          <v-expansion-panels class="mb-4" multiple>
            <v-expansion-panel
              v-for="(item,i) in faq.items"
              :key="i"
            >
              <v-expansion-panel-header class="font-weight-black">{{ item.title }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-html="item.content" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/*
|---------------------------------------------------------------------
| Help Page Component
|---------------------------------------------------------------------
|
| Template to show frequently asked questions to your users
|
*/
export default {
  data() {
    return {
      search: '',

      faqs: [
        {
          id: 'troubleshooting',
          title: 'Troubleshooting Tips',
          items: [
            {
              title: 'What should I do if the site or app crashes?',
              content: 'Like any other site or app, it is possible that the site or app might crash from time to time. Most issues can be resolved by refreshing the page. Click the refresh button on your browser or app screen.',
            },
            {
              title: "What if refreshing doesn't work?",
              content: "If you are using the mobile app version, fully close the app on your Android or Apple device, then reopen it and sign in again. Here's how to fully close an app:<br><ul><li>Apple: <a href='https://www.youtube.com/watch?v=crYhPYX0OaU' target='_blank'>Click here for instructions</a></li><li>Android: <a href='https://www.youtube.com/watch?v=cM3A9Z4zkFY' target='_blank'>Click here for instructions</a></li></ul>",
            },
            {
              title: 'What if I still have problems?',
              content: "Contact our technical support team at <a href='mailto:support@schooldogsolutions.com'>support@schooldogsolutions.com</a> or call <a href='tel:+14708767070'>470-876-7070</a>. We'll quickly assist you so you can get back to using SchoolDog smoothly!",
            },
          ],
        },
      ],
    }
  },
}
</script>
