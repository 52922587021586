<template>
<div class="combobox-container" :class="name">
  <v-combobox
    v-on="$listeners"
    v-bind="$attrs"
    :attach="
      ($vuetify.breakpoint.smAndUp || preventAttach) ? void 0 : `.${name}`
    "
    :items="items"
    ref="combobox"
    hide-details
    :label="label"
    outlined
    dense
  ></v-combobox>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    name: String,
    schoolId: String,
    headerText: {
      type: String,
      default: null,
    },
    minOccurrences: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: 'Location',
    },
    preventAttach: Boolean,
  },
  computed: {
    ...mapState('app', [
      'schoolLocationsById',
    ]),
    items () {
      if (this.headerText) {
        return [
          {
            header: this.headerText,
          },
          ...this.mappedSchoolLocations,
        ]
      }
      return [
        ...this.mappedSchoolLocations,
      ]
    },
    mappedSchoolLocations() {
      const schoolLocations = this.schoolLocationsById[this.schoolId];
      if (!schoolLocations || !schoolLocations.usageMap) {
        return [];
      }
      const keys = Object.keys(schoolLocations.usageMap).filter(key => schoolLocations.usageMap[key] >= this.minOccurrences);
      return keys.sort((a, b) => {
        return (
          schoolLocations.usageMap[b] - schoolLocations.usageMap[a]
        );
      });
    },
  },
  methods: {
    // Called by parent
    blur () {
      if (this.$refs.combobox) {
        this.$refs.combobox.blur();
      }
    },
  },
}
</script>