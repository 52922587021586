<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
    <v-sheet class="layout-side mx-auto mx-md-1 d-none d-md-flex flex-md-column justify-space-between px-2">
      <div class="mt-3 mt-md-10 pa-2">
        <div class="px-6">
          <v-img :src="require('@/assets/images/logo.png')" color="transparent" contain></v-img>
        </div>
        <div class="title my-2">A Technology Toolbox for Schools.<br/>A Faithful Friend for School Leaders.</div>
      </div>
      <img class="w-full" src="@/assets/images/children_books.jpeg" />
    </v-sheet>

    <div class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column">
      <img v-if="!$vuetify.breakpoint.mdAndUp" :src="require('@/assets/images/logo.svg')" style="max-height: 40px" />
      <div class="layout-content ma-auto w-full">
        <v-card v-if="isOffline" class="pa-1">
          <v-card-title class="justify-center display-1 mb-2">
            You are offline
          </v-card-title>
          <v-card-text>
            In order to securely verify your identity, please try signing in once you have stable internet connection. Once you are signed in, most elements of SchoolDog will work while offline or in low internet environments.
          </v-card-text>
        </v-card>
        <div v-show="!isOffline">
          <slot></slot>
        </div>
      </div>
      <div class="overline mt-4 mx-1 d-flex" style="max-width: 100%; width: 320px;">
        <div v-if="showGaBranding" class="d-flex justify-center mr-5" style="flex-direction: column;">
          <div style="flex: 1" />
          <img src="@/assets/images/georgia_flag.png" style="width: 48px" />
          <div style="flex: 1" />
        </div>
        <div>
          <div>
            SchoolDog Solutions, Inc. <span v-if="showGaBranding">is proudly a "Georgia Grown" company</span><span v-else>was</span> founded by a retired <span v-if="showGaBranding">Georgia </span>K-12 superintendent
          </div>
        </div>
      </div>
      <div class="d-flex mt-1">
        <a href="/terms">Terms and Conditions</a>
        <span class="mx-1">•</span>
        <a href="/privacy">Privacy Policy</a>
      </div>
      <div class="text--secondary mt-1">© Copyright 2025 by SCHOOLDOG Solutions, Inc.</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('app', [
      'isOffline',
      'showGaBranding',
    ]),
  },
}
</script>

<style scoped>
.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}
</style>
