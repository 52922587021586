<template>
  <v-toolbar
      color="#ffffff"
      style="border-bottom: 1px solid #1b7476;"
      width="100%">

      <div class="flex-grow-1" />

      <!-- Date Range Picker -->
      <v-menu
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="isShowingDateRangeMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="dateRangeText"
            label="Date Range"
            class="mr-2"
            prepend-inner-icon="mdi-calendar"
            readonly
            hide-details="auto"
            outlined
            dense
            style="width: 240px; max-width: 240px;"
            v-on="on"
            clearable
            @click:clear="filters.dateRange = []"
          ></v-text-field>
        </template>
        <v-date-picker
          range
          v-model="filters.dateRange"
          @input="datesSelected"
        ></v-date-picker>
      </v-menu>


      <!-- Multi-select for Schools -->
      <v-select
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="filters.schoolIds"
        hide-details="auto"
        :items="schoolItems"
        :menu-props="{ offsetY: true }"
        outlined
        dense
        label="Schools"
        style="max-width: 256px;"
        multiple>
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">
            {{ filters.schoolIds.length > 1 ? `${filters.schoolIds.length} Schools Selected` : item.text }}
          </span>
        </template>
      </v-select>

      <!-- Filter Button with Dropdown Menu -->
      <v-menu :close-on-content-click="false" offset-y max-width="480px" min-width="320px">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" color="grey darken-1" class="ml-1">
            Filters
            <v-icon>mdi-filter-variant</v-icon>
            <v-badge v-if="numberOfActiveMenuFilters" color="primary" :content="numberOfActiveMenuFilters" />
          </v-btn>
        </template>
        <v-list class="pb-2">
          <div class="pt-2 px-2 text-h6">
            Filters
          </div>
          <v-list-item v-if="!$vuetify.breakpoint.mdAndUp">
            <v-menu
              v-model="isShowingDateRangeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="dateRangeText"
                  label="Date Range"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details="auto"
                  outlined
                  dense
                  v-on="on"
                  clearable
                  @click:clear="filters.dateRange = []"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="filters.dateRange"
              ></v-date-picker>
            </v-menu>
          </v-list-item>
          <v-list-item>
            <users-select
              class="mb-10"
              v-model="filters.userIds"
              hide-details="auto"
              outlined
              show-self
              dense
              label="Recorded by"
              :preventAttach="true"
              multiple />
          </v-list-item>
          <v-list-item v-if="!$vuetify.breakpoint.mdAndUp">
            <v-select v-model="filters.schoolIds" hide-details="auto" :items="schoolItems" outlined dense label="Schools" multiple :menu-props="{ offsetY: true }">
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">
                  {{ filters.schoolIds.length > 1 ? `${filters.schoolIds.length} Schools Selected` : item.text }}
                </span>
              </template>
            </v-select>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import UsersSelect from './UsersSelect.vue';

export default {
  components: {
    UsersSelect,
  },
  props: {
    filters: Object,
    permissions: String,
  },
  data() {
    return {
      isShowingDateRangeMenu: false,
    };
  },
  computed: {
    ...mapState('app', [
      'user',
    ]),
    ...mapGetters('app', [
      'getSchoolById',
      'schools',
    ]),
    numberOfActiveMenuFilters () {
      let number = 0;
      if (this.$vuetify.breakpoint.mdAndUp) {

        if (this.filters.userIds.length > 0) {
          number++;
        }
      } else {
        if (this.filters.dateRange.length === 2) {
          number++;
        }
        if (this.filters.userIds.length > 0) {
          number++;
        }
        if (this.filters.schoolIds.length > 0) {
          number++;
        }
      }
      return number;
    },
    schoolItems () {
      const allSchoolIds = [];
      this.permissions.split('|').forEach(permission => {
        const perms = this.user.getLimitedSchoolIdsForPermission(permission);
        if (perms) {
          allSchoolIds.push(...perms);
        }
      });
      const limitedSchoolIds = [...new Set(allSchoolIds)];
      return this.schools
        .filter(school => !limitedSchoolIds || limitedSchoolIds.length == 0 || limitedSchoolIds.includes(school.id))
        .map(school => ({
          text: school.name,
          value: school.id,
        })).sort((a, b) => a.text.localeCompare(b.text));
    },
    dateRangeText() {
      if (this.filters.dateRange.length === 2) {
        // Format the dates as needed
        const startDate = new Date(`${this.filters.dateRange[0]} 00:00:00`).toLocaleDateString();
        const endDate = new Date(`${this.filters.dateRange[1]} 00:00:00`).toLocaleDateString();
        return `${startDate} - ${endDate}`;
      } else {
        return 'All Time';
      }
    },
  },
  methods: {
    datesSelected() {
      if (!this.filters.dateRange || this.filters.dateRange.length != 2) {
        return;
      }
      // if both dates are selected, sort them
      const startDate = new Date(this.filters.dateRange[0].replace(/-/g, '/'));
      const endDate = new Date(this.filters.dateRange[1].replace(/-/g, '/'));
      if (startDate > endDate) {
        const newStart = this.filters.dateRange[1];
        this.filters.dateRange[1] = this.filters.dateRange[0];
        this.filters.dateRange[0] = newStart;
      }
    },
  },
}
</script>
