<template>
  <v-card
    class="d-flex flex-column flex-grow-1 pa-1">
    <v-card-title>My SchoolDog Walks</v-card-title>
    <v-card-text class="d-flex flex-column fill-height">
      <div class="text-subtitle-1 mb-1">
        There {{ inProgressWalksAwaitingAction.length === 1 ? 'is' : 'are' }} {{ inProgressWalksAwaitingAction.length }} In-Progress SchoolDog Walk{{ inProgressWalksAwaitingAction.length === 1 ? '' : 's' }} awaiting your action.
      </div>
      <div v-if="ledWalksMoreThanSevenDays.length" class="d-flex mb-2">
        <div class="mr-1">
          <v-icon color="warning">mdi-alert</v-icon>
        </div>
        <div class="mb-1">
          <a @click.stop.prevent="isOldWalkDialogVisible = true" href="#">Manage {{ ledWalksMoreThanSevenDays.length }} In-Progress SchoolDog Walk{{ ledWalksMoreThanSevenDays.length === 1 ? '' : 's' }} more than seven days old.</a>
        </div>
      </div>
      <div>
        <div v-if="inProgressWalksAwaitingAction.length < 6" class="mx-2">
          <div v-for="walk in inProgressWalksAwaitingAction" :key="walk.id" class="mb-1">
            <router-link v-if="getSchoolById(walk.schoolId)" :to="`/walks/walk/${walk.id}/inprogress`" text color="primary">
              <v-icon color="primary">mdi-walk</v-icon>
              {{ walk.timeCreated | formatDate }} at {{ getSchoolById(walk.schoolId).name }}
            </router-link>
          </div>
        </div>
        <div class="mt-2">
          <v-btn to="/walks" text color="primary">
            View SchoolDog Walks
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <div v-if="inProgressWalksAwaitingAction.length">
          <v-btn to="/walks?awaitingMyAction=true" text color="primary">
            View SchoolDog Walks awaiting my action
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </div>
      <div class="flex-grow-1" />
      <div v-if="$canAtAnySchool('log entries')" @click="isNewWalkDialogVisible = true" class="d-flex justify-center mt-2">
        <v-btn color="primary" rounded large>
          Start new SchoolDog Walk
        </v-btn>
      </div>
    </v-card-text>

    <create-walk-dialog v-model="isNewWalkDialogVisible" />

    <v-dialog v-model="isOldWalkDialogVisible" max-width="600px" :persistent="isSubmittingCompletion">
      <v-card>
        <v-progress-linear
          v-if="isSubmittingCompletion"
          indeterminate
          color="primary" />
        <v-card-title>
          Ready to submit?
          <v-spacer />
          <v-btn v-if="!isSubmittingCompletion" icon @click="isOldWalkDialogVisible = false" color="grey darken-1">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          Your SchoolDog Walk data is not actionable until you complete it.
        </v-card-subtitle>
        <v-card-text>
          <div class="text-body-1 mb-2">
            You are currently leading {{ ledWalksMoreThanSevenDays.length }} In-Progress SchoolDog Walk{{ ledWalksMoreThanSevenDays.length === 1 ? '' : 's' }} that {{ ledWalksMoreThanSevenDays.length === 1 ? 'is' : 'are' }} more than seven days old. Please ensure the data is correct, then complete {{ ledWalksMoreThanSevenDays.length === 1 ? 'it' : 'them' }}. If a SchoolDog Walk was only created for training or testing purposes, please delete it once you no longer need it.
          </div>
          <div v-if="!isSubmittingCompletion" class="mx-2">
            <div v-for="walk in ledWalksMoreThanSevenDays" :key="walk.id" class="mb-1 d-flex">
              <router-link v-if="getSchoolById(walk.schoolId)" :to="`/walks/walk/${walk.id}/complete`" text color="primary">
                <v-icon color="primary">mdi-walk</v-icon>
                {{ walk.timeCreated | formatDate }} at {{ getSchoolById(walk.schoolId).name }} ({{ getNumberOfEntriesForWalk(walk) }} entr{{ getNumberOfEntriesForWalk(walk) === 1 ? 'y' : 'ies' }})
              </router-link>
              <v-spacer />
              <v-btn @click="startDeletingWalk(walk)" icon>
                <v-icon color="red darken-1">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else class="mx-2 mb-2">
            <div v-for="walk in ledWalksMoreThanSevenDays" :key="walk.id" class="mb-1 d-flex">
              <v-icon color="primary">mdi-walk</v-icon>
              {{ walk.timeCreated | formatDate }} at {{ getSchoolById(walk.schoolId).name }} ({{ getNumberOfEntriesForWalk(walk) }} entr{{ getNumberOfEntriesForWalk(walk) === 1 ? 'y' : 'ies' }})
            </div>
          </div>
          <div class="mx-2">
            <v-btn v-if="ledWalksMoreThanSevenDays.length" @click="completeAllledWalksMoreThanSevenDays()" width="100%" color="primary" :loading="isSubmittingCompletion" :disabled="isSubmittingCompletion">
              Complete all above SchoolDog Walks
            </v-btn>
            <div v-if="isCompletionErrorVisible" class="text-caption error--text mt-1 text-center">
              Some SchoolDog Walks could not be completed. Please try again or open the SchoolDog Walk to resolve potential issues.
            </div>
          </div>
        </v-card-text>
        <v-card-actions v-if="!isSubmittingCompletion">
          <v-spacer />
          <v-btn @click="isOldWalkDialogVisible = false" text color="grey darken-1">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isDeleteWalkDialogVisible" persistent max-width="528px">
      <v-card v-if="toDeleteWalk">
        <v-card-title>
          Are you sure?
        </v-card-title>
        <v-card-subtitle>
          Are you sure you want to delete the SchoolDog Walk from {{ getSchoolById(toDeleteWalk.schoolId).name }} on {{ toDeleteWalk.timeCreated | formatDate }} with {{ getNumberOfEntriesForWalk(toDeleteWalk) }} entr{{ getNumberOfEntriesForWalk(toDeleteWalk) === 1 ? 'y' : 'ies' }}? This action cannot be undone.
        </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isSubmittingDeletion"
              @click="isDeleteWalkDialogVisible = false"
              color="grey darken-1"
              text
              >No, Do Not Delete
              </v-btn>
              <v-btn
              @click="deleteWalk()"
              :disabled="isSubmittingDeletion"
              :loading="isSubmittingDeletion"
              color="primary"
              >Yes, Delete
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import * as Sentry from '@sentry/vue';
import CreateWalkDialog from '../walks/CreateWalkDialog.vue'

export default {
  components: {
    CreateWalkDialog,
  },
  data () {
    return {
      isOldWalkDialogVisible: false,
      isNewWalkDialogVisible: false,
      isSubmittingCompletion: false,
      isCompletionErrorVisible: false,
      isDeleteWalkDialogVisible: false,
      isSubmittingDeletion: false,
      toDeleteWalk: null,
    }
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
      'schools',
    ]),
    ...mapState('app', [
      'user',
      'walks',
    ]),
    inProgressWalksAwaitingAction () {
      const walks = this.walks.filter(walk => {
        if (walk.timeArchived) {
          return false
        }
        if ('inprogress' !== walk.status) {
          return false
        }
        if (walk.leaderUserId !== this.user.id && !(walk.collaborators || []).includes(this.user.id)) {
          return false
        }
        return true
      })
      walks.sort((a, b) => {
        return b.timeCreated > a.timeCreated ? 1 : -1
      })
      return walks
    },
    inProgressLedWalks () {
      const walks = this.walks.filter(walk => {
        if (walk.timeArchived) {
          return false
        }
        if ('inprogress' !== walk.status) {
          return false
        }
        if (walk.leaderUserId !== this.user.id) {
          return false
        }
        return true
      })
      return walks
    },
    schoolItems() {
      if (!this.schools) {
        return [];
      }
      return this.schools.map((school) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
    },
    completedWalks () {
      const walks = this.walks.filter(walk => {
        if (walk.timeArchived) {
          return false
        }
        if ('complete' !== walk.status) {
          return false
        }
        if ((walk.leaderUserId !== this.user.id && !(walk.collaborators || []).includes(this.user.id))) {
          return false
        }
        return true
      })
      return walks
    },
    ledWalksMoreThanSevenDays () {
      const now = new Date()
      const sevenDaysAgo = new Date(now)
      sevenDaysAgo.setDate(now.getDate() - 7)
      const sevenDaysAgoTimestamp = sevenDaysAgo.getTime()
      const ledWalksMoreThanSevenDays = this.inProgressLedWalks.filter(walk => {
        return walk.timeCreated.toDate().getTime() < sevenDaysAgoTimestamp
      })
      return ledWalksMoreThanSevenDays.sort((a, b) => {
        return b.timeCreated > a.timeCreated ? 1 : -1
      })
    },
  },
  methods: {
    ...mapActions('app', [
      'showSuccess',
      'showError',
      'syncWalks',
    ]),
    ...mapMutations('app', [
      'removeWalk',
    ]),
    getNumberOfEntriesForWalk (walk) {
      return Object.values(walk.observationsById || {}).length
    },
    async completeAllledWalksMoreThanSevenDays () {
      this.isSubmittingCompletion = true
      const successfulCompletions = []
      const failedCompletions = []
      for (const walk of this.ledWalksMoreThanSevenDays) {
        try {
          await this.runFunction('completeWalk', {
            walkId: walk.id,
          })
          walk.status = 'complete'
          successfulCompletions.push(walk)
        } catch (e) {
          failedCompletions.push(walk)
          Sentry.captureException(e)
        }
      }
      if (successfulCompletions.length) {
        this.showSuccess(`Successfully completed ${successfulCompletions.length} SchoolDog Walk${successfulCompletions.length === 1 ? '' : 's'}.`)
      }
      if (failedCompletions.length) {
        this.isCompletionErrorVisible = true
      } else if (successfulCompletions.length) {
        this.isOldWalkDialogVisible = false
        await this.syncWalks()
      }
      this.isSubmittingCompletion = false
    },
    startDeletingWalk (walk) {
      this.toDeleteWalk = walk
      this.isDeleteWalkDialogVisible = true
    },
    async deleteWalk () {
      try {
        this.isSubmittingDeletion = true;
        await this.runFunction('archiveWalk', {
          walkId: this.toDeleteWalk.id,
        })
        this.showSuccess('Successfully deleted SchoolDog Walk');
        this.removeWalk(this.toDeleteWalk)
      } catch {
        this.showError('Failed to delete SchoolDog Walk');
      } finally {
        this.isDeleteWalkDialogVisible = false;
        this.toDeleteWalk = null;
        this.isSubmittingDeletion = false;
      }
    },
  },
  mounted () {
    if (this.ledWalksMoreThanSevenDays.length) {
      this.isOldWalkDialogVisible = true
    }
  },
}
</script>

<style>
</style>
