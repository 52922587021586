<template>
<div class="combobox-container" :class="name">
  <v-combobox
    v-bind="$attrs"
    @input="setObservationItemId($event)"
    :value="
      value
        ? {
            value: value,
            text: observationItemById[
              value
            ].label,
          }
        : null
    "
    :attach="
      $vuetify.breakpoint.smAndUp ? false : `.${name}`
    "
    :items="
      observationCategoryId
        ? observationOptionsByCategoryId[
            observationCategoryId
          ]
        : observationOptionsWithCategories
    "
    class="wrap-text-dropdown"
    full-width
    clearable
    dense
    hide-details="auto"
    label="Observation"
    outlined
    validate-on-blur
  ></v-combobox>
</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    value: String,  // The id
    observationCategoryId: String,
    name: String,
  },
  computed: {
    ...mapState('app', [
      'observationSettings',
    ]),
    observationOptionsByCategoryId() {
      const map = {};
      this.observationCategories.forEach((category) => {
        map[category.id] = this.observationItemsByCategory[category.id]
          .map((observationItem) => ({
            text: observationItem.label,
            value: observationItem.id,
          }))
          .sort((a, b) => {
            if (a.text === 'Other' && b.text === 'Other') return 0;
            if (a.text === 'Other') return 1;
            if (b.text === 'Other') return -1;
            return a.text.localeCompare(b.text);
          });
      });
      return map;
    },
    observationItemById() {
      const map = {};
      this.observationItems.forEach((observationItem) => {
        map[observationItem.id] = observationItem;
      });
      return map;
    },
    observationItemsByCategory() {
      const map = {};
      if (!this.observationSettings) {
        return map;
      }
      this.observationSettings.items.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          if (!map[categoryId]) {
            map[categoryId] = [];
          }
          map[categoryId].push(observationItem);
        });
      });
      return map;
    },
    observationOptionsWithCategories() {
      const options = [];
      this.observationItems.forEach((observationItem) => {
        observationItem.categories.forEach((categoryId) => {
          options.push({
            text: `${observationItem.label} (${this.observationCategoryById[categoryId].label})`,
            value: `${categoryId}__${observationItem.id}`,
          });
        });
      });
      return options.sort((a, b) => {
        if (a.text === 'Other' && b.text === 'Other') return 0;
        if (a.text === 'Other') return 1;
        if (b.text === 'Other') return -1;
        return a.text.localeCompare(b.text);
      });
    },
    observationItems() {
      if (this.observationSettings) {
        return this.observationSettings.items;
      }
      return [];
    },
    observationCategories() {
      if (this.observationSettings) {
        return this.observationSettings.categories;
      }
      return [];
    },
    observationCategoryById() {
      const map = {};
      this.observationCategories.forEach((observationCategory) => {
        map[observationCategory.id] = observationCategory;
      });
      return map;
    },
  },
  methods: {
    setObservationItemId(observationItem) {
      if (!observationItem) {
        this.$emit('input', null);
        return;
      }
      const observationItemId = observationItem.value;
      if (observationItemId && observationItemId.includes('__')) {
        const parts = observationItemId.split('__');
        this.$emit('update:observationCategoryId', parts[0])
        this.$emit('input', parts[1])
      } else {
        this.$emit('input', observationItemId)
      }
    },
  },
}
</script>