<template>
  <VDialog
    :value="value"
    @input="$emit('input', $event)"
    max-width="600"
  >
    <v-card>
      <v-card-title>Assign Rating</v-card-title>
      <v-card-text>
        <school-select v-model="schoolId" permission="submit ratings" />
        <br>
        <VSelect
          v-model="rubricId"
          :items="activeRubrics"
          item-text="name"
          item-value="id"
          label="Rubric"
          outlined
          dense
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)" color="grey darken-1" text> Cancel </v-btn>
        <v-btn
          @click="createRubricRatingInternal"
          :disabled="!schoolId || !rubricId || creatingRubricRating"
          color="primary"
        >
          Assign Rating
        </v-btn>
      </v-card-actions>
    </v-card>
  </VDialog>
</template>

<script>
import SchoolSelect from '../common/SchoolSelect.vue'
import { mapActions, mapState } from 'vuex';
export default {
  components: { SchoolSelect },
  props: {
    value: null,
  },
  data() {
    return {
      rubricId: null,
      schoolId: null,
      creatingRubricRating: false,
    }
  },
  computed: {
    ...mapState('app', [
      'rubrics',
      'user',
    ]),
    activeRubrics() {
      return this.rubrics.filter(rubric => rubric.enabled);
    },
  },
  methods: {
    ...mapActions('app', [
      'createRubricRating',
    ]),
    async createRubricRatingInternal() {
      this.creatingRubricRating = true;
      if (this.rubricId && this.schoolId) {
        const rubricRating = await this.createRubricRating({
          rubricId: this.rubricId,
          schoolId: this.schoolId,
          createdByUserId: this.user.id,
        });
        this.$emit('input', false);
        this.$router.push({
          name: 'rubric-ratings-edit',
          params: {
            rubricRatingId: rubricRating.id,
          },
        });
        this.creatingRubricRating = false;
      }
    },
  },
}
</script>

<style>

</style>
