<template>
  <v-dialog v-model="dialog" width="600" style="z-index: 10000">
    <v-card>
      <v-card-title>
        {{ isEdit ? 'Edit Task' : 'Propose Task' }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <!-- <v-card-subtitle v-if="editTask.id">
        Task ID: {{ editTask.id }}
      </v-card-subtitle> -->

        <v-card-text>
          <v-form v-model="isTaskFormValid" ref="taskForm">
          <div class="text-subtitle-1 mb-1">Required Fields</div>
            <school-select
              v-model="editTask.schoolId"
              class="mb-1"
              clearable
              outlined
              dense
              :rules="[v => !!v || 'School is required']"
              :permission="'propose tasks'"
              label="School"
              hide-details="auto"></school-select>

            <users-select
              v-model="editTask.assignedToUserId"
              :disabled="!editTask.schoolId"
              :rules="[v => !!v || 'Proposed User is required']"
              dense
              label="Propose Task to"
              persistent-hint
              hide-details="auto"
              show-self
              :valid-user-ids="getEligibleTaskAssigneesForTask(editTask).map(user => user.value)"
              outlined
              class="mb-1" />

          <v-text-field
            v-model="editTask.title"
            class="mb-1"
            outlined
            dense
            label="Task Title"
            hide-details="auto"
            required
            :rules="[v => !!v || 'Title is required']"
          ></v-text-field>

          <v-textarea
            v-model="editTask.description"
            class="mb-2"
            label="Task Description"
            hide-details="auto"
            outlined
            dense
            required
            
          ></v-textarea>
          <div class="text-subtitle-1 mb-1">Optional Fields</div>
          <v-text-field
            v-model="editTask.location"
            class="mb-1"
            outlined
            dense
            label="Location"
            hide-details="auto"></v-text-field>
          <v-menu
            ref="menu"
            v-model="isDueDateMenuOpen"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="editTask.dueDate"
                label="Due Date"
                outlined
                dense
                clearable
                hide-details="auto"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="editTask.dueDate" no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="$refs.menu.save(editTask.dueDate)">{{ $t('common.save') }}</v-btn>
              <v-btn text color="primary" @click="$refs.menu.cancel()">{{ $t('common.cancel') }}</v-btn>
            </v-date-picker>
          </v-menu>
          <compressed-image-input
            v-model="editTask.pictureFiles"
            multiple
          ></compressed-image-input>
          <!-- <v-checkbox v-model="editTask.shouldEmailCreatorWhenComplete" label="Send me an email when Task is completed" hide-details></v-checkbox> -->
          </v-form>
        </v-card-text>

      <v-card-actions class="pa-2">
        <v-spacer></v-spacer>
        <v-btn color="grey darken-1" text @click="close">{{ $t('common.cancel') }}</v-btn>
        <v-btn color="primary" @click="save" :disabled="isSaving" :loading="isSaving">{{ $t('common.save') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import uuid from 'uuid'
import ImageUploadService from '@/services/ImageUploadService'
import CompressedImageInput from '../common/CompressedImageInput.vue'
import Task from '../../models/Task'
import SchoolSelect from '../common/SchoolSelect.vue'
import UsersSelect from '../common/UsersSelect.vue'

/*
|---------------------------------------------------------------------
| TODO Compose Component
|---------------------------------------------------------------------
|
| Compose new tasks editor
|
*/
export default {
  name: 'TaskCompose',
  components: {
    SchoolSelect,
    UsersSelect,
    CompressedImageInput,
  },
  props: {
    // whether we want an input event or an actual save
    walkId: {
      type: String,
      required: false,
    },
    observation: {
      type: Object,
      required: false,
    },
  },
  data () {
    return {
      dialog: false,
      title: '',
      description: '',
      editTask: {},

      isTaskFormValid: false,

      search: null,

      isDueDateMenuOpen: false,

      initialTask: null,

      isSaving: false,
    }
  },
  computed: {
    ...mapGetters('app', [
      'getEligibleTaskAssigneesForTask',
    ]),
    ...mapState('app', [
      'user',
      'users',
    ]),
    isEdit() {
      return this.editTask && this.editTask.id
    },
  },
  methods: {
    ...mapActions('app', [
      'syncTasks',
      'showSuccess',
    ]),
    async save () {
      await this.$refs.taskForm.validate()
      if (this.isTaskFormValid) {
        try {
          this.isSaving = true
          if (this.editTask.pictureFiles) {
            const newPictures = await this.uploadPictures(this.editTask);
            this.editTask.imagePaths =
            [
              ...(this.editTask.imagePaths || []),
              ...newPictures,
            ]
          }
          delete this.editTask.pictureFiles;
          const task = this.initialTask || new Task(null, {
            status: 'Assigned',
            createdByUserId: this.user.id,
          })
          
          Object.keys(this.editTask).forEach((key) => {
            task[key] = this.editTask[key]
          })
          this.close()
          if (this.walkId) {
            this.$emit('input', task)
          } else {
            await task.save()
            this.showSuccess('Task proposed successfully')
            this.syncTasks()
          }
        } finally {
          this.isSaving = false
        }
      }
    },
    open (task) {
      if (this.$refs.taskForm) {
        this.$refs.taskForm.resetValidation()
      }
      this.initialTask = task
      if (task) {
        this.editTask = task.clone()
      } else {
        this.editTask = {}
      }
      if (this.walkId) {
        this.editTask.walkId = this.walkId
      }
      if (this.observation && !task) {
        if (this.observation.observationType === 'concern') {
          this.editTask.title = 'Address this Concern'
        }
        this.editTask.description = this.observation.comment
        this.editTask.location = this.observation.location
      }
      this.dialog = true
    },
    close () {
      this.$refs.taskForm.resetValidation()
      this.dialog = false
    },
    async uploadPictures() {
      if (!this.editTask.pictureFiles) {
        return [];
      }

      const imagePaths = [];

      this.editTask.pictureFiles.forEach((file) => {
        const storageRef = ImageUploadService.beginFileUpload(`tasks/${this.user.id}/${uuid()}`, file);
        imagePaths.push(storageRef.fullPath);
      });

      return imagePaths;
    },
  },
}
</script>
