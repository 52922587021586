<template>
<div class="combobox-container" :class="name">
  <v-combobox
    v-on="$listeners"
    v-bind="$attrs"
    :attach="
      (preventAttach || $vuetify.breakpoint.smAndUp) ? void 0 : `.${name}`
    "
    :items="items"
    ref="combobox"
    hide-details
    label="Tag"
    outlined
    dense
    :menu-props="{ offsetY: true }"
  ></v-combobox>
</div>
</template>

<script>
import TagCount from '../../models/TagCount';

export default {
  props: {
    name: String,
    schoolId: String,
    headerText: {
      type: String,
      default: null,
    },
    minOccurrences: {
      type: Number,
      default: 1,
    },
    preventAttach: Boolean,
  },
  data () {
    return {
      tagCount: null,
    }
  },
  computed: {
    items () {
      if (this.headerText) {
        return [
          {
            header: this.headerText,
          },
          ...this.mappedTags,
        ]
      }
      return [
        ...this.mappedTags,
      ]
    },
    mappedTags() {
      if (!this.tagCount || !this.tagCount.usageMap) {
        return [];
      }
      const keys = Object.keys(this.tagCount.usageMap).filter(key => this.tagCount.usageMap[key] >= this.minOccurrences);
      return keys.sort((a, b) => {
        return (
          this.tagCount.usageMap[b] - this.tagCount.usageMap[a]
        );
      });
    },
  },
  async mounted () {
    this.tagCount = await TagCount.getById('global');
  },
}
</script>