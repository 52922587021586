<template>
  <v-card
    class="d-flex flex-column flex-grow-1 pa-1">
    <v-card-title>Recent Ratings</v-card-title>
    <v-card-text>
      <school-select
        v-model="selectedSchoolId"
        default-to-first-value />
      <v-select
        v-if="rubricIdOptions.length"
        v-model="selectedRubricId"
        :items="rubricIdOptions"
        item-text="name"
        item-value="rubricId"
        label="Rubric"
        outlined
        dense
        hide-details="auto"
        class="mt-1"
        style="width: 100%"
      />
      <div v-if="selectedSchoolId && selectedRubricId && recentRatingsBySchoolIdByRubricId[selectedSchoolId][selectedRubricId] && recentRatingsBySchoolIdByRubricId[selectedSchoolId][selectedRubricId].length" class="d-flex flex-wrap mt-1">
        <v-col
          v-for="rating in recentRatingsBySchoolIdByRubricId[selectedSchoolId][selectedRubricId].slice(0, 4)"
          cols="6"
          class="d-flex flex-column justify-center align-center pa-0 pt-2"
          :key="rating.id">
          <rubric-score
            :rubric="rating"
            :size="80" />
          <div class="text-center">
            <router-link v-if="rating.walkId" :to="`walks/walk/${rating.walkId}/complete`">
              {{ rating.timeSubmitted | formatDate('MM/DD') }} SchoolDog Walk
            </router-link>
            <router-link v-else :to="`rubric/ratings/assigned/${rating.id}`">
              {{ rating.timeSubmitted | formatDate('MM/DD') }} Assigned Rating
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" class="d-flex justify-center pa-0">
          <v-btn color="primary" text class="mt-2" @click="isViewAllDialogVisible = true">View all <v-icon>mdi-chevron-right</v-icon></v-btn>
        </v-col>
      </div>
      <div v-else>
        <div class="text-subtitle-1 grey--text text--darken-1 pa-2 text-center">
          There are no recent ratings for this school.
        </div>
      </div>
    </v-card-text>
    <v-dialog v-model="isViewAllDialogVisible" max-width="600px">
      <v-card v-if="selectedRubric && selectedSchool && isViewAllDialogVisible">
        <v-card-title class="flex-nowrap align-start">
          {{ selectedRubric.name }} rubrics at {{ selectedSchool.name }}
          <v-spacer />
          <v-btn color="grey darken-1" icon @click="isViewAllDialogVisible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-wrap">
            <v-col
              v-for="rating in recentRatingsBySchoolIdByRubricId[selectedSchoolId][selectedRubricId].slice(0, numVisibleRubrics)"
              cols="6"
              class="d-flex flex-column justify-center align-center pa-0 pt-2"
              :key="rating.id">
              <rubric-score
                :rubric="rating"
                :size="80" />
              <div class="text-center">
                <router-link v-if="rating.walkId" :to="`walks/walk/${rating.walkId}/complete`">
                  {{ rating.timeSubmitted | formatDate('MM/DD') }} SchoolDog Walk
                </router-link>
                <router-link v-else :to="`rubric/ratings/assigned/${rating.id}`">
                  {{ rating.timeSubmitted | formatDate('MM/DD') }} Assigned Rating
                </router-link>
              </div>
            </v-col>
            <v-col v-if="hasMoreToShow" cols="12" class="d-flex justify-center pa-0">
              <v-btn color="primary" text class="mt-2" @click="numVisibleRubrics += 10">Show more</v-btn>
            </v-col>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="isViewAllDialogVisible = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import SchoolSelect from '../common/SchoolSelect.vue'
import RubricScore from '../rubric/RubricScore.vue'

export default {
  components: { SchoolSelect, RubricScore },
  data () {
    return {
      selectedSchoolId: null,
      selectedRubricId: null,
      isViewAllDialogVisible: false,
      numVisibleRubrics: 10,
    }
  },
  computed: {
    ...mapState('app', [
      'usersById',
      'rubricRatings',
    ]),
    ...mapGetters('app', [
      'getSchoolById',
      'getRubricById',
    ]),
    recentRubrics() {
      return this.rubricRatings.filter(rating => rating.status == 'submitted').sort((a, b) => a.timeSubmitted > b.timeSubmitted ? -1 : 1);
    },
    recentRatingsBySchoolIdByRubricId() {
      const ratingsBySchoolIdByRubricId = {}
      this.recentRubrics.forEach(rating => {
        if (!ratingsBySchoolIdByRubricId[rating.schoolId]) {
          ratingsBySchoolIdByRubricId[rating.schoolId] = {}
        }
        if (!ratingsBySchoolIdByRubricId[rating.schoolId][rating.rubricId]) {
          ratingsBySchoolIdByRubricId[rating.schoolId][rating.rubricId] = []
        }
        ratingsBySchoolIdByRubricId[rating.schoolId][rating.rubricId].push(rating)
      })
      return ratingsBySchoolIdByRubricId
    },
    rubricIdOptions() {
      if (!this.selectedSchoolId) {
        return []
      }
      if (!this.recentRatingsBySchoolIdByRubricId[this.selectedSchoolId]) {
        return []
      }
      const options = [];
      Object.keys(this.recentRatingsBySchoolIdByRubricId[this.selectedSchoolId]).forEach(rubricId => {
        options.push({rubricId, name: this.getRubricById(rubricId).name})
      })
      return options;
    },
    selectedRubric () {
      if (!this.selectedRubricId) {
        return null
      }
      return this.getRubricById(this.selectedRubricId);
    },
    selectedSchool() {
      return this.getSchoolById(this.selectedSchoolId)
    },
    hasMoreToShow() {
      return this.recentRatingsBySchoolIdByRubricId[this.selectedSchoolId][this.selectedRubricId].length > this.numVisibleRubrics
    },
  },
  watch: {
    selectedSchoolId() {
      if (!((this.recentRatingsBySchoolIdByRubricId ?? {})[this.selectedSchoolId] ?? {})[this.selectedRubricId]) {
        this.selectedRubricId = this.rubricIdOptions[0].rubricId
      }
    },
    isViewAllDialogVisible(val) {
      if (!val) {
        this.numVisibleRubrics = 10
      }
    },
  },
}
</script>

<style>
</style>
