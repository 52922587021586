import FirestoreModel from './FirestoreModel';

class TopWalkersLeaderboard extends FirestoreModel {
    static collectionName = 'leaderboards';
    static allowedFields = [
      'data',
    ]

    static async get() {
      return await this.getById('topWalkers');
    }

    constructor(id, data) {
      super(id, data);
    }
}

export default TopWalkersLeaderboard;