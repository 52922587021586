var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"combobox-container",class:_vm.name},[_c('v-combobox',_vm._b({staticClass:"wrap-text-dropdown",attrs:{"value":_vm.value
        ? {
            value: _vm.value,
            text: _vm.observationItemById[
              _vm.value
            ].label,
          }
        : null,"attach":_vm.$vuetify.breakpoint.smAndUp ? false : `.${_vm.name}`,"items":_vm.observationCategoryId
        ? _vm.observationOptionsByCategoryId[
            _vm.observationCategoryId
          ]
        : _vm.observationOptionsWithCategories,"full-width":"","clearable":"","dense":"","hide-details":"auto","label":"Observation","outlined":"","validate-on-blur":""},on:{"input":function($event){return _vm.setObservationItemId($event)}}},'v-combobox',_vm.$attrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }