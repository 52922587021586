<template>
  <v-card>
    <div class="text-subtitle-1 px-3 pt-3">
      Timeline
    </div>
    <v-card-text>
      <div>
        <v-timeline class="pa-0 mt-1" dense align-top>
          <v-timeline-item
            v-for="(item, index) in mappedSubmissionHistory"
            :key="index"
            :color="item.color"
            small
          >
            <strong>{{ statusLabels[item.toStatus] }}</strong>
            <div v-if="item.comments" class="my-1 text-body-1">Revisions Requested: <i>"{{ item.comments }}"</i></div>
            <div class="caption">
              <div>
                {{ item.notifiedEmails.length }}
                {{
                  item.notifiedEmails.length === 1 ? "person" : "people"
                }}
                notified via email
              </div>
              <div class="grey--text">
                {{ item.timestamp | formatDate("M/D/YYYY") }} at
                {{ item.timestamp | formatDate("h:mma") }}.
              </div>
            </div>
          </v-timeline-item>
          <v-timeline-item :color="'green'" small>
            <strong>SchoolDog Walk Started</strong>
            <div class="caption">
              <div class="grey--text">
                {{ walk.timeCreated | formatDate("M/D/YYYY") }} at
                {{ walk.timeCreated | formatDate("h:mma") }}.
              </div>
            </div>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Timestamp } from 'firebase/firestore';
export default {
  name: 'WalkHistoryCard',
  props: {
    walk: Object,
  },
  data() {
    return {
      statusLabels: {
        review: 'Submitted for Review',
        inprogress: 'Placed In Progress',
        complete: 'Marked Complete',
      },
    };
  },
  computed: {
    mappedSubmissionHistory() {
      const submissionHistory = JSON.parse(
        JSON.stringify(this.walk.submissionHistory.map((item) => {
          return {
            ...item,
            timestamp: item.timestamp.toDate(),
          };
        })),
      );
      submissionHistory.forEach((item) => {
        item.timestamp = Timestamp.fromDate(new Date(item.timestamp));
      });
      let previousStatus = null;
      for (let i = 0; i < submissionHistory.length; i++) {
        const item = submissionHistory[i];
        if (i === submissionHistory.length - 1) {
          item.color = 'primary';
        } else if (item.toStatus === 'complete') {
          item.color = 'green';
        } else if (item.toStatus === 'inprogress') {
          if (previousStatus === 'review') {
            item.color = 'orange';
          } else {
            item.color = 'blue';
          }
        } else if (item.toStatus === 'review') {
          item.color = 'blue';
        }
        previousStatus = item.toStatus;
      }
      submissionHistory.reverse();
      return submissionHistory;
    },
  },
};
</script>
<style lang="scss">
</style>