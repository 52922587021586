/* eslint-disable quotes */
exports.appConfig =
{
  'apiKey': 'AIzaSyAPRCwzr3aTDjn7QfcIvcU-nas1tgzgxCA',
  'appId': '1:1046093033778:web:97d71aa201acded20c672b',
  'authDomain': 'schooldog-i-alex-dev.firebaseapp.com',
  'measurementId': 'G-638E8J7EGP',
  'messagingSenderId': '1046093033778',
  'project': 'schooldog-i-alex-dev',
  'projectId': 'schooldog-i-alex-dev',
  'showGaBranding': true,
  'storageBucket': 'schooldog-i-alex-dev.appspot.com',
}
