import FirestoreModel from './FirestoreModel';

class Drill extends FirestoreModel {
    static collectionName = 'drills';
    static allowedFields = [
      'type',
      'otherType',
      'eventType',
      'otherEventType',
      'schoolId',
      'responseTime',
      'drillDate', // YYYY-MM-DD
      'drillTime', // HH:MM
      'drillTimeOfDay', // AM/PM
      'numberOfParticipants',
      'additionalInfo',
      'resultsAdditionalInfo',
      'recordedByUserId',
    ]

    constructor(id, data) {
      super(id, data);
    }

    getDateTime () {
      // factor in date, hours, minutes, and time of day
      const date = new Date(this.drillDate);
      const hours = this.drillTime.split(':')[0] + (this.drillTimeOfDay === 'pm' ? 12 : 0);
      const minutes = this.drillTime.split(':')[1];

      date.setHours(hours);
      date.setMinutes(minutes);

      return date;
    }

    getFriendlyDrillType () {
      if (this.type === 'other') {
        return this.otherType;
      }

      return {
        fire: 'Fire',
        severeWeather: 'Severe Weather',
        intruder: 'Intruder/Lockdown',
      }[this.type] || 'Unknown';
      
    }

    getFriendlyEventType () {
      if (this.eventType === 'other') {
        return this.otherEventType;
      }

      return {
        planned: 'Planned drill',
        tableTop: 'Table-top exercise',
        real: 'Real event',
      }[this.eventType] || 'Unknown';
    }
}

export default Drill;
