import FirestoreModel from './FirestoreModel';

class Rubric extends FirestoreModel {
    static collectionName = 'rubricRatings';
    static allowedFields = [
      'name',
      'enabled',
      'allEntries',
      'districtWalksOnly',
      'skipByDefault',
      'timeCreated',
      'createdByUserId',
      'timeUpdated',
      'updatedByUserId',
      'timeDeleted',
      'deletedByUserId',
      'isDeleted',
      'timeSubmitted',
      'submittedByUserId',
      'skip',
      'timeSkipped',
      'skippedByUserId',
      'sections',
      'useScoreThresholds',
      'scoreThresholds',
      'totalScore',
      'maxScore',
      'score',
      'rubricId',
      'walkId',
      'schoolId',
      'status',
    ]

    constructor(id, data) {
      super(id, data);
      if (id && !this.scoreThresholds) {
        this.useScoreThresholds = false;
        this.scoreThresholds = {};
      }
    }
}

export default Rubric;
