<template>
<div class="d-flex" style="width: 100%; max-width: 100%;">
  <div class="flex-grow-1" style="width: 100%; max-width: 100%;">
    <toolbar-filter :filters="filters" permissions="record drills"/>
    <div class="d-flex flex-column flex-grow-1 px-4 pb-4 pt-2">
        <div class="text-h5 mb-1">
            Drills
        </div>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="isLoadingDrills"
            :items-per-page="50"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortByDesc"
            class="elevation-1">
            <template v-slot:[`item.recordedBy`]="{ item }">
                <user-link v-if="usersById[item.recordedByUserId]" :user-id="item.recordedByUserId" />
                <div v-else>Unknown</div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <v-btn
                    color="grey darken-1"
                    icon
                    @click="$router.push(`/drills/drill/${item.id}`)"
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CheckPermissionMixin from '../../mixins/CheckPermissionMixin';
import Drill from '../../models/Drill';
import UserLink from '../../components/common/UserLink.vue';
import ToolbarFilter from '../../components/common/ToolbarFilter.vue';

export default {
  name: 'ViewDrillsPage',
  mixins: [
    CheckPermissionMixin,
  ],
  canAtAnySchool: 'record drills',
  components: {
    UserLink,
    ToolbarFilter,
  },
  data () {
    return {
      filters: {
        dateRange: [],
        userIds: [],
        schoolIds: [],
        drillTypes: [],
        eventTypes: [],
      },
      drills: [],
      isLoadingDrills: false,
      sortBy: 'date',
      sortByDesc: true,
    }
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
      'schools',
    ]),
    ...mapState('app', [
      'usersById',
      'user',
    ]),
    headers () {
      return [
        { text: 'School', value: 'schoolName' },
        { text: 'Date', value: 'date' },
        { text: 'Drill Type', value: 'drillType' },
        { text: 'Recorded By', value: 'recordedBy' },
        { text: '', value: 'action', sortable: false },
      ];
    },
    items () {
      return this.filteredDrills.map(drill => ({
        ...drill,
        schoolName: this.getSchoolById(drill.schoolId).name,
        recordedBy: this.usersById[drill.recordedByUserId] ? `${this.usersById[drill.recordedByUserId].firstName} ${this.usersById[drill.recordedByUserId].lastName}` : 'Unknown',
        date: this.$moment(drill.drillDate).format('MM/DD/YYYY'),
        drillType: drill.getFriendlyDrillType(),
      }));
    },
    filteredDrills () {
      return this.drills.filter(drill => {
        if (this.filters.dateRange.length === 2) {
          const drillDate = drill.drillDate;
          const startDate = this.filters.dateRange[0];
          const endDate = this.filters.dateRange[1];
          if (drillDate < startDate || drillDate > endDate) {
            return false;
          }
        }
        if (this.filters.userIds.length > 0 && !this.filters.userIds.includes(drill.recordedByUserId)) {
          return false;
        }
        if (this.filters.schoolIds.length > 0 && !this.filters.schoolIds.includes(drill.schoolId)) {
          return false;
        }
        return true;
      });
    },
  },
  methods: {
    async loadDrills () {
      this.isLoadingDrills = true;
      try {
        const limitedSchoolIds = this.user.getLimitedSchoolIdsForPermission('record drills');
        if (!limitedSchoolIds) {
          this.drills = await Drill.getAll();
        } else {
          this.drills = await Drill.getAllWhere([
            [
              'schoolId',
              'in',
              limitedSchoolIds,
            ],
          ]);
        }
      } finally {
        this.isLoadingDrills = false;
      }
    },
    handleQueryParams() {
      const query = this.$route.query;
      if (query.dateRange) {
        this.filters.dateRange = query.dateRange.split(',');
      }
      if (query.schoolIds) {
        this.filters.schoolIds = query.schoolIds.split(',');
      }
      if (query.walkTypes) {
        this.filters.walkTypes = query.walkTypes.split(',');
      }
      if (query.userIds) {
        this.filters.userIds = query.userIds.split(',');
      }
      if (query.awaitingMyAction) {
        this.filters.awaitingMyAction = query.awaitingMyAction === 'true';
      }
      if (query.showArchived) {
        this.filters.showArchived = query.showArchived === 'true';
      }
      if (query.currentTab) {
        this.currentTab = parseInt(query.currentTab);
      }
    },
  },
  mounted () {
    this.handleQueryParams();
    this.loadDrills();
  },
  watch: {
    filters: {
      handler () {
        this.$router.replace({
          query: {
            ...this.filters,
            dateRange: this.filters.dateRange.join(','),
            schoolIds: this.filters.schoolIds.join(','),
            userIds: this.filters.userIds.join(','),
          },
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-select__selections {
  height: 40px;
  overflow: ell;
}
</style>
