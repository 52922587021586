<template>
  <v-list v-if="organizationSettings || !user" nav dense class="fill-height d-flex flex-column">
    <div v-for="(item, index) in calculatedMenu" :key="index">
      <div v-if="isItemValid(item)">
        <div v-if="item.key || item.text" class="pa-1 mt-1 overline">{{ item.key ? $t(item.key) : item.text }}</div>
        <nav-menu :menu="item.items" />
      </div>
    </div>
    <div class="flex-grow-1" />
    <div v-if="showGaBranding" class="overline mt-2 d-flex full-width align-center">
      <div class="d-flex justify-center mr-2" style="flex-direction: column;">
        <div style="flex: 1" />
        <img src="@/assets/images/georgia_flag.png" style="width: 48px" />
        <div style="flex: 1" />
      </div>
      <div>
        Proudly "Georgia Grown"
      </div>
    </div>
    <div class="text--secondary py-1">
      © Copyright 2025 by SCHOOLDOG Solutions, Inc.
    </div>
  </v-list>
</template>

<script>
import NavMenu from './NavMenu'
import { mapGetters, mapState } from 'vuex'

export default {
  components: {
    NavMenu,
  },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    ...mapState('app', [
      'organizationSettings',
      'user',
      'showGaBranding',
    ]),
    calculatedMenu() {
      const menu = JSON.parse(JSON.stringify(this.menu))
      if (this.user) {
        if (this.user.isDistrictUser() || this.user.can('manage district')) {
          return menu.map((menuItem) => {
            menuItem = JSON.parse(JSON.stringify(menuItem))
            if (menuItem.text === 'System') {
              menuItem.items.unshift({
                icon: 'mdi-school',
                text: this.organizationSettings?.district?.name,
                link: `/schools`,
                requireDistrictAdministrator: true,
              })
            }
            if (menuItem.text === 'Resources') {
              if (this.showGaBranding) {
                menuItem.items.splice(menuItem.items.length - 1, 0, {
                  image: 'gasroe.png',
                  text: 'GASROE School Safety Toolkit',
                  link: `https://scenaind.com/gasroe-toolkit`,
                  isExternal: true,
                  exact: true,
                })
              }
            }
            return menuItem
          })
        }
        return menu.map((menuItem) => {
          menuItem = JSON.parse(JSON.stringify(menuItem))
          if (menuItem.text === 'System') {
            this.user.roleSchoolIds.forEach((schoolId) => {
              menuItem.items.unshift({
                icon: 'mdi-school',
                text: this.getSchoolById(schoolId)?.name,
                link: `/schools/${schoolId}`,
              })
            })
          }
          if (menuItem.text === 'Resources') {
            if (this.showGaBranding) {
              menuItem.items.splice(menuItem.items.length - 1, 0, {
                image: 'gasroe.png',
                text: 'GASROE School Safety Toolkit',
                link: `https://scenaind.com/gasroe-toolkit`,
                isExternal: true,
                exact: true,
              })
            }
          }
          return menuItem
        })
      }
      return [
        {
          text: 'Pages',
          isForUnauthedUsers: true,
          items: [
            { icon: 'mdi-account', text: 'Sign In', link: '/auth/signin', isForUnauthedUsers: true },
            { icon: 'mdi-file-document-outline', key: 'menu.legalSection.termsOfUse', text: 'Terms and Conditions', link: '/terms', isForUnauthedUsers: true },
            { icon: 'mdi-file-eye-outline', key: 'menu.legalSection.privacyPolicy', text: 'Privacy Policy', link: '/privacy', isForUnauthedUsers: true },
          ],
        },
      ]
    },
  },
  methods: {
    isItemValid (item) {
      if (item.isForUnauthedUsers && !this.user) {
        return true
      } else if (item.isForUnauthedUsers) {
        return false
      }
      if (!this.user) {
        return false
      }
      if (this.user.isSchoolDogStaff) {
        return true
      }
      if (item.requireSchoolDogStaff) {
        return false
      }
      if (item.permissions) {
        return this.$getResolvedPermission(item.permissions)
      }
      return true
    },
  },
}
</script>
