<template>
<div v-if="user && organizationSettings" class="mb-2">
    <div class="text-h5">
    Welcome, {{ user.firstName }}!
    </div>
    <div class="text-subtitle-1 text--secondary">
      <span v-if="true">
          You are using SchoolDog as a <b>{{ user.friendlyRole }}{{schoolText}}</b>.
      </span>
    </div>
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    ...mapState('app', [
      'organizationSettings',
      'user',
    ]),
    schoolText () {
      if ([
        'school_safety_team',
        'school_safety_leader',
        'maintenance_work_order_manager',
        'school_resource_officer',
      ].includes(this.user.role)) {
        const schoolIds = this.user.roleSchoolIds;
        if (!schoolIds.length) {
          return null;
        }
        if (schoolIds.length === 1) {
          if (!this.getSchoolById(schoolIds[0])) {
            return null;
          }
          return ` for ${this.getSchoolById(schoolIds[0]).name}`;
        } else if (schoolIds.length <= 10) {
          let str = '';
          schoolIds.forEach((schoolId, i) => {
            if (!this.getSchoolById(schoolId)) {
              return;
            }
            if (i === schoolIds.length - 1) {
              str += ` and ${this.getSchoolById(schoolId).name}`;
            } else {
              str += `${this.getSchoolById(schoolId).name}, `;
            }
          });
          return ` for ${str}`;
        } else {
          return ` for ${schoolIds.length} schools`;
        }
      }
      return null;
    },
  },
}
</script>

<style>

</style>