import { db } from '@/firebase-setup'
import { addDoc, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where, Timestamp, documentId, deleteDoc } from 'firebase/firestore';

import store from './../store'

class FirestoreService {
  static async createDocument(collectionName, data) {
    data.timeCreated = Timestamp.now()
    data.timeUpdated = data.timeCreated

    // Create the document in Firestore
    let docRef = null
    if (store.state.app.isOffline) {
      // 20 characters including upper and lower case letters and numbers
      let id = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < 20; i++) {
        id += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      
      docRef = doc(db, collectionName, id)
      setDoc(docRef, data)
    } else {
      docRef = await addDoc(collection(db, collectionName), data);
    }

    // Fetch the newly created document to get all its fields (in case there are some default values or triggers in Firestore)
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      throw new Error('Failed to fetch the newly created document');
    }

    const fetchedData = docSnap.data();

    return {
      id: docRef.id,
      ...fetchedData,
    };
  }

  static async fetchAllDocuments(collectionName) {
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(collectionRef);

    const documents = [];
    querySnapshot.forEach(docSnap => {
      if (docSnap.metadata.fromCache) {
        console.log('Fetched from cache', docSnap.id)
      }
      const data = docSnap.data();
      documents.push({
        id: docSnap.id,
        ...data,
      });
    });

    return documents;
  }

  static async fetchAllDocumentsWhere(collectionName, conditions = []) {
    const colRef = collection(db, collectionName);
    let q = query(colRef);

    for (const condition of conditions) {
      const [
        fieldPath,
        opStr,
        value,
      ] = condition;
      q = query(q, where(fieldPath === '__documentId__' ? documentId() : fieldPath, opStr, value));
    }

    const querySnapshot = await getDocs(q);

    const documents = [];
    querySnapshot.forEach((doc) => {
      documents.push({ id: doc.id, ...doc.data() });
    });
    return documents;
  }

  static async fetchDocument(collection, docId) {
    const docRef = doc(db, collection, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    } else {
      throw new Error(`Document not found in collection ${collection} with id ${docId}`);
    }
  }

  static async saveDocument(collection, docId, data, shouldOverwrite = false) {
    const docRef = doc(db, collection, docId);
    data.timeUpdated = Timestamp.now()
    if (shouldOverwrite) {
      await setDoc(docRef, data)
    }
    await updateDoc(docRef, data);
  }

  static async deleteDocument(collection, docId) {
    const docRef = doc(db, collection, docId);
    await deleteDoc(docRef);
  }
}

export default FirestoreService;
