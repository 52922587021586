<template>
  <v-card
    class="d-flex flex-column flex-grow-1 pa-1">
    <v-card-title><glossary-term text="School Leaderboard" /></v-card-title>
    <div
      v-if="isLoading"
      style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
      <v-progress-circular
        indeterminate
        color="primary" />
    </div>
    <v-card-text v-else>
      <v-select
        v-model="currentMonth"
        :items="monthOptions"
        label="Safety Cycle"
        outlined
        hide-details="auto"
        dense
        />
      <div v-if="!schoolsByObservationCount.length" class="text-center my-3">
        No entries logged for this cycle.
      </div>
      <v-list v-else>
        <v-list-item v-for="(school) in schoolsByObservationCount" :key="school.name">
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <v-avatar
                class="mr-1"
                size="24"
                left
                :style="`border: 2px solid ${school.rankColor} !important;`"
              >
                {{ school.rank }}
              </v-avatar>
              <div>
                <div>{{ school.name }}</div>
                <div class="font-weight-thin">{{ numObservationsBySchoolId[school.id] || '0' }} entr{{ numObservationsBySchoolId[school.id] === 1 ? 'y' : 'ies' }} logged</div>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div class="d-flex justify-center">
        <v-btn @click="isFullLeaderboardDialogVisible = true" text color="primary">
          View full leaderboard
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-card-text>
    <v-dialog
      v-model="isFullLeaderboardDialogVisible"
      max-width="528px">
      <v-card>
        <v-card-title>Full Leaderboard</v-card-title>
        <v-card-subtitle>
          Schools are sorted alphabetically when viewing the full leaderboard.
        </v-card-subtitle>
        <v-card-text>
        <v-select
          v-model="currentMonth"
          :items="monthOptions"
          label="Safety Cycle"
          outlined
          hide-details="auto"
          dense
          />
          <v-list>
            <v-list-item v-for="(school) in allSchoolsWithObservationCount" :key="school.id">
              <v-list-item-content>
                <v-list-item-title class="d-flex align-center mb-1">
                  <div>
                    <div>{{ school.name }}</div>
                    <div class="font-weight-thin">{{ numObservationsBySchoolId[school.id] || '0' }} entr{{ numObservationsBySchoolId[school.id] === 1 ? 'y' : 'ies' }} logged</div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isFullLeaderboardDialogVisible = false" color="grey darken-1" text>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import GlossaryTerm from '../common/GlossaryTerm.vue'
import InsightCount from '../../models/InsightCount'

export default {
  components: {
    GlossaryTerm,
  },
  data () {
    return {
      insightCount: null,
      isLoading: false,
      isFullLeaderboardDialogVisible: false,
      currentMonth: null,
    }
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
      'schools',
    ]),
    ...mapState('app', [
      'organizationSettings',
    ]),
    firstMonth () {
      if (!this.insightCount || !this.insightCount.countsByMonth) {
        return null;
      }
      return Object.keys(this.insightCount.countsByMonth).sort()[0]
    },
    lastMonth () {
      if (!this.insightCount || !this.insightCount.countsByMonth) {
        return null;
      }
      return Object.keys(this.insightCount.countsByMonth).sort().reverse()[0]
    },
    monthOptions () {
      if (!this.insightCount || !this.insightCount.countsByMonth) {
        return []
      }
      const firstMonth = this.firstMonth
      const lastMonth = this.lastMonth
      const months = []
      let currentMonth = lastMonth
      let i = 0;
      while (currentMonth >= firstMonth && i++ < 36) {
        months.push({
          value: moment(currentMonth).format('YYYY-MM'),
          text: moment(currentMonth).format('MMMM YYYY'),
        })
        currentMonth = moment(currentMonth).subtract(1, 'month').format('YYYY-MM')
      }
      return months
    },
    numObservationsBySchoolId () {
      if (!this.insightCount || !this.insightCount.countsByMonth || !this.currentMonth || !this.insightCount.countsByMonth[this.currentMonth] || !this.insightCount.countsByMonth[this.currentMonth].countsBySchool) {
        return {}
      }
      const res = {}
      for (const [
        schoolId,
        v,
      ] of Object.entries(this.insightCount.countsByMonth[this.currentMonth].countsBySchool)) {
        res[schoolId] = v.totalObservations
      }
      return res
    },
    schoolsByObservationCount () {
      if (!this.currentMonth) {
        return []
      }
      const top3 = JSON.parse(JSON.stringify(this.schools))
      top3.sort((a, b) => {
        return Number(this.numObservationsBySchoolId[b.id] || 0) - Number(this.numObservationsBySchoolId[a.id] || 0)
      })
      return top3.slice(0, 3).map((school) => {
        return {
          ...school,
          observationCount: this.numObservationsBySchoolId[school.id],
          rank: top3.indexOf(school.id) + 1,
          rankColor: [
            '#FFD700',
            '#C0C0C0',
            '#cd7f32',
          ][top3.indexOf(school)],
          rankIcon: [
            'mdi-podium-gold',
            'mdi-podium-silver',
            'mdi-podium-bronze',
          ][top3.indexOf(school)],
        }
      })
    },
    allSchoolsWithObservationCount () {
      const schools = this.schools.toSorted((a, b) => {
        return a.name > b.name ? 1 : -1
      })
      return schools.map((school) => {
        const schoolId = school.id
        return {
          ...this.getSchoolById(schoolId),
          observationCount: this.numObservationsBySchoolId[schoolId] || 0,
        }
      })
    },
  },
  methods: {
    formatDate (dateStr) {
      const date = new Date(dateStr.substring(0, 10) + ' 12:00:00')
      const year = date.getFullYear()
      const monthStr = date.toLocaleString('default', { month: 'short' })
      const day = date.getDate()
      return `${monthStr} ${day}, ${year}`
    },
  },
  async mounted () {
    this.isLoading = true;
    try {
      this.insightCount = await InsightCount.getById('global')
      if (this.lastMonth) {
        this.currentMonth = this.lastMonth
      }
    } catch {} finally {
      this.isLoading = false;
    }
  },
}
</script>

<style>
</style>
