import FirestoreModel from './FirestoreModel';

class InsightCount extends FirestoreModel {
    static collectionName = 'insightCounts';
    static allowedFields = [
      'count',
      'countsByMonth',
      'countsBySchool',
    ]

    constructor(id, data) {
      super(id, data);
    }
}

export default InsightCount;