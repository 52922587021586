<template>
  <div v-if="drill" class="flex-grow-1 pa-4" style="width: 100%; max-width: 100%;">
    <div class="d-flex">
      <v-btn to="/drills" text color="primary" class="mb-2" active-class="active-route">
        <v-icon>mdi-chevron-left</v-icon>
        View all drills
      </v-btn>
      <v-spacer />
      <v-btn color="primary" :to="`/drills/update/${drill.id}`" text>
        <v-icon class="mr-1">mdi-pencil</v-icon>  Update Drill
      </v-btn>
    </div>
    <div class="mb-1">
      <div class="text-h5">
        Drill #{{ drill.id }}
      </div>
    </div>
    <v-card>
      <v-card-title class="flex-grow-1 flex-row d-flex">
        Submission Information
      </v-card-title>
      <v-card-text>
        <div class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Recorded By
          </div>
          <div class="font-weight-medium">
            <user-link :userId="drill.recordedByUserId" />
          </div>
        </div>
        <div class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Time Recorded
          </div>
          <div class="font-weight-medium">
            {{ drill.timeCreated | formatDate('M/D/YYYY h:mm A') }}
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-card-title class="flex-grow-1 flex-row d-flex">
        Drill Information
      </v-card-title>
      <v-card-text>
        <div class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            School
          </div>
          <div class="font-weight-medium">
            {{ getSchoolById(drill.schoolId).name }}
          </div>
        </div>
        <div class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Drill Type
          </div>
          <div class="font-weight-medium">
            {{ drill.getFriendlyDrillType()}}
          </div>
        </div>
        <div class="text-body-2 mb-1" v-if="drill.type === 'other'">
          <div class="font-weight-thin text-caption">
            Drill Type (Other)
          </div>
          <div class="font-weight-medium">
            {{ drill.otherType}}
          </div>
        </div>
        <div class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Drill Date
          </div>
          <div class="font-weight-medium">
            {{ drill.drillDate}}
          </div>
        </div>
        <div class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Drill Time
          </div>
          <div class="font-weight-medium">
            {{ drill.drillTime }} {{ drill.drillTimeOfDay }}
          </div>
        </div>
        <div class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Event Type
          </div>
          <div class="font-weight-medium">
            {{ drill.getFriendlyEventType() }}
          </div>
        </div>
        <div class="text-body-2 mb-1" v-if="drill.eventType === 'other'">
          <div class="font-weight-thin text-caption">
            Event Type (Other)
          </div>
          <div class="font-weight-medium">
            {{ drill.otherEventType}}
          </div>
        </div>
        <div class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Additional Information
          </div>
          <div class="font-weight-medium">
            {{ drill.additionalInfo || 'N/A' }}
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-if="drill.responseTime || drill.resultsAdditionalInfo"
      class="mt-2">
      <v-card-title class="flex-grow-1 flex-row d-flex">
        Drill Results
      </v-card-title>
      <v-card-text>
        <div v-if="drill.responseTime" class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Response Time
          </div>
          <div class="font-weight-medium">
            {{ formattedDrillResponseTime }}
          </div>
        </div>
        <div v-if="drill.numberOfParticipants !== null" class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Number of Participants
          </div>
          <div class="font-weight-medium">
            {{ drill.numberOfParticipants || 'N/A' }}
          </div>
        </div>
        <div class="text-body-2 mb-1">
          <div class="font-weight-thin text-caption">
            Additional Information
          </div>
          <div class="font-weight-medium">
            {{ drill.resultsAdditionalInfo || 'N/A' }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserLink from '../../components/common/UserLink.vue';
import CheckPermissionMixin from '../../mixins/CheckPermissionMixin';
import Drill from '../../models/Drill';


export default {
  components: {
    UserLink,
  },
  mixins: [
    CheckPermissionMixin,
  ],
  canAtAnySchool: 'record drills',
  data () {
    return {
      drill: null,
    };
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    formattedDrillResponseTime () {
      const minutes = Number(this.drill.responseTime.slice(0, 2));
      const seconds = Number(this.drill.responseTime.slice(3));
      return `${minutes} minutes, ${seconds} seconds`;
    },
    isDrillDateInFuture() {
      if (!this.drill.drillDate) {
        return false
      }
      return new Date(this.drill.drillDate) > new Date()
    },
  },
  methods: {
    async loadDrill () {
      this.drill = await Drill.getById(this.$route.params.drillId)
    },
  },
  mounted () {
    this.loadDrill();
  },
};
</script>
<style lang="scss" scoped>
.active-route::before {
  opacity: 0;
}
</style>